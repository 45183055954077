import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ recaptchaKey, verifyCallback }) => {
  return (
    <ReCAPTCHA
      sitekey={recaptchaKey}
      onChange={(response) => verifyCallback(true, response)}
    />
  );
};

export default Captcha;
