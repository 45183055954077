import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import ConditionalElement from "../ConditionalElement";

const TextBoxWidget = ({ variant, layout, text, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <ConditionalElement
          tagName="div"
          condition={layout === "shifted"}
          attributes={{ className: "shift-2" }}
          childrenElements={
            <div className={layout === "shifted" ? "row" : "col-12"}>
              <div
                className={`text-box ${
                  variant === "centered" ? "centered" : ""
                } ${layout === "shifted" ? "col-12" : ""}`}
              >
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
          }
        />
      </div>
    </div>
  </Container>
);

TextBoxWidget.title = "Text";
TextBoxWidget.category = "General";

TextBoxWidget.propTypes = {
  variant: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

TextBoxWidget.defaults = {
  variant: "normal",
  layout: "full-width",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum porttitor semper est in porta. Vestibulum fringilla justo in magna ornare, non commodo justo consectetur. Mauris ut tristique nisi, sed tristique lectus. Sed diam mauris, aliquet quis imperdiet sed, viverra sit amet lorem. Quisque urna sapien, molestie a bibendum nec, tempor vitae magna.",
  containerSettings: {},
};

TextBoxWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["normal", "Normal"],
      ["centered", "Centered"],
    ],
  },
  layout: {
    type: "select",
    options: [
      ["full-width", "Full Width"],
      ["shifted", "Shifted"],
    ],
  },
  text: { type: "custom", component: RichText },
};

TextBoxWidget.dialogSize = "large";

export default TextBoxWidget;
