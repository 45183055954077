/* eslint-disable no-undef */
import { get, patch, post, del } from "../../helpers/http";

const getToken = () =>
  document.querySelector('meta[name="csrf-token"]').content;

export default class MediaManager {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  findAll(callback, params = {}) {
    get(`${this.baseUrl}images`, params)
      .then((r) => r.json())
      .then((images) => callback(images));
  }

  uploadFile(file, callback) {
    const body = new FormData();
    body.append("image[asset]", file);

    post(
      `${this.baseUrl}images`,
      {},
      { body, headers: { "X-CSRF-Token": getToken() } },
    )
      .then((r) => r.json())
      .then((image) => callback(image));
  }

  update(image, params, callback) {
    patch(
      `${this.baseUrl}images/${image.id}`,
      { image: params },
      {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": getToken(),
        },
      },
    )
      .then((r) => r.json())
      .then((updatedImage) => callback(updatedImage));
  }

  destroy(image, callback) {
    del(
      `${this.baseUrl}images/${image.id}`,
      {},
      {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": getToken(),
        },
      },
    )
      .then((r) => r.json())
      .then((destroyedImage) => callback(destroyedImage));
  }
}
