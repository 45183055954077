import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  Img,
  TextInput,
  TextArea,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";

const imageType = (image) => {
  if (typeof image === "string") {
    return image;
  }

  return image.urls.original;
};

class ImageSliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSlide: 0,
    };
  }

  handleSelectSlide(idx) {
    this.setState({ selectedSlide: idx });
  }

  renderNavButtons() {
    return this.props.list.map((slideBtn, idx) => (
      <button
        key={idx}
        className={`btn-slide ${
          idx === this.state.selectedSlide ? "selected-slide" : ""
        }`}
        onClick={() => this.handleSelectSlide(idx)}
      >
        <Img src={slideBtn.image} variant="image_slider_thumb" />
      </button>
    ));
  }

  renderSlides() {
    return this.props.list.map((slide, idx) => (
      <div
        className={`image-slide ${
          idx === this.state.selectedSlide ? "current-slide" : ""
        }`}
        style={{ backgroundImage: `url(${imageType(slide.image)})` }}
        key={idx}
      >
        <div className="slide-text">
          <h1 className="slide-title">{slide.title}</h1>
          <h2 className="slide-subtitle">{slide.subTitle}</h2>
          <div className="description-wrapper">
            <p>{slide.description}</p>
          </div>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <Container
        className="image-slider"
        settings={this.props.containerSettings}
      >
        <div className="slides-wrapper">{this.renderSlides()}</div>

        <div className="slides-nav">{this.renderNavButtons()}</div>
      </Container>
    );
  }
}

ImageSliderWidget.title = "Image Slider";
ImageSliderWidget.category = "Media";
ImageSliderWidget.dialogSize = "large";

ImageSliderWidget.defaultProps = {
  list: [],
  containerSettings: {},
};

ImageSliderWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object,
};

ImageSliderWidget.defaults = {
  list: [
    {
      id: 1,
      image: imagePlaceholder("1590x419"),
      title: "H1 REGULAR ALL CAPS",
      subTitle: "h2 Thin Italic two lines max",
      description:
        "h6 regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
    },
    {
      id: 2,
      image: imagePlaceholder("1590x419"),
      title: "H1 REGULAR ALL CAPS",
      subTitle: "h2 Thin Italic two lines max",
      description:
        "h6 regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
    },
    {
      id: 3,
      image: imagePlaceholder("1590x419"),
      title: "H1 REGULAR ALL CAPS",
      subTitle: "h2 Thin Italic two lines max",
      description:
        "h6 regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
    },
  ],
  containerSettings: {},
};

ImageSliderWidget.adminFields = {
  image: { type: "custom", component: "ImagePicker" },
  description: { type: "text" },
};

const SlideFields = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Sub Title"
      onChange={(value) => onChangeItem("subTitle", value)}
      value={settings.subTitle}
    />

    <TextArea
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

SlideFields.defaultProps = {
  settings: {},
};

SlideFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ImageSliderWidget.adminFields = (items, onChange) => (
  <List
    listItem={(settings, onChangeItem) => (
      <SlideFields settings={settings} onChangeItem={onChangeItem} />
    )}
    onChangeList={onChange}
    items={items}
    titleField="title"
    template={[
      {
        image: imagePlaceholder("1590x419"),
        title: "H1 REGULAR ALL CAPS",
        subTitle: "h2 Thin Italic two lines max",
        description:
          "h6 regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
      },
    ]}
    primaryField="title"
  />
);

export default ImageSliderWidget;
