import React from "react";

const Voucher = ({ data, onChange, onApply }) => (
  <React.Fragment>
    <div className="form-row">
      <input
        type="text"
        value={data.code}
        onChange={(e) => onChange("code", e.target.value)}
        name="order[discount_code]"
      />

      <button type="button" className="btn-primary" onClick={() => onApply()}>
        Приложи
      </button>
    </div>

    {data.message && (
      <div className="form-row">
        <div className="success-message">{data.message}</div>
      </div>
    )}

    {data.error && (
      <div className="form-row">
        <div className="error-message">{data.error}</div>
      </div>
    )}
  </React.Fragment>
);

export default Voucher;
