import React from "react";
import Currency from "./Currency";

const voucherDiscount = (voucher, totalWithDelivery) => {
  if (voucher.discountType === "fixed") return voucher.discount;
  if (voucher.discountType === "percent")
    return (
      (parseFloat(totalWithDelivery) / 100) * parseInt(voucher.discount, 10)
    );

  return 0;
};

const customerDiscount = (customer, total) => {
  return (parseFloat(total) / 100) * parseFloat(customer.discount_percentage);
};

const totalAmount = (voucher, customer, totals) => {
  const { deliveryPrice, totalWithDelivery } = totals;

  if (shouldHaveVoucherDiscount(voucher, customer)) {
    return totalWithDelivery - voucherDiscount(voucher, totalWithDelivery);
  }

  if (shouldHaveCustomerdiscount(customer)) {
    return (
      totalWithDelivery -
      customerDiscount(customer, totalWithDelivery - deliveryPrice)
    );
  }

  return totalWithDelivery;
};

const shouldHaveVoucherDiscount = (voucher, customer) => {
  if (customer && customer.discount_percentage > 0) return false;

  return voucher && voucher.discount;
};

const shouldHaveCustomerdiscount = (customer) =>
  customer && customer.discount_percentage > 0;

const getDeliveryPrice = (courier, cart, voucher) => {
  if (courier) {
    return isDeliveryFree(cart, voucher) ? 0 : parseFloat(courier.price);
  }

  return 0;
};

const isDeliveryFree = (cart, voucher) => {
  if (voucher && voucher.freeDelivery === true) {
    return true;
  }

  if (cart) {
    return cart.cart_items.some((ci) => ci.product_free_delivery === true);
  }

  return false;
};

const orderTotals = (cart, courier, voucher) => {
  const deliveryPrice = getDeliveryPrice(courier, cart, voucher);
  const cartTotal = parseFloat(cart.total);
  const totalWithDelivery = courier ? cartTotal + deliveryPrice : cartTotal;

  return {
    deliveryPrice,
    cartTotal,
    totalWithDelivery,
  };
};

const cartHasDiscount = (cart) =>
  cart.cart_items
    .filter((i) => i.personalizations.length > 0)
    .filter((i) => i.quantity > 2).length > 0;

const OrderSummary = ({ cart, customer, voucher, delivery, couriers }) => {
  const courier = couriers.find((c) => c.id === delivery.method);
  const totals = orderTotals(cart, courier, voucher);
  const freeDelivery = isDeliveryFree(cart, voucher);
  const hasDiscount = cartHasDiscount(cart);
  const { deliveryPrice, cartTotal, totalWithDelivery } = totals;

  return (
    <div className="checkout-section monochrome">
      <h4>Общо</h4>
      <div className="fields">
        <div className="subtotal-item">
          <div>Цена</div>
          <div>
            <Currency number={cartTotal} />
          </div>
        </div>

        {courier && (
          <div className="subtotal-item">
            <div>{courier.label}</div>
            <div>
              {freeDelivery ? (
                "Безплатна доставка"
              ) : (
                <Currency number={deliveryPrice} />
              )}
            </div>
          </div>
        )}

        {shouldHaveVoucherDiscount(voucher, customer) && (
          <div className="subtotal-item">
            <div className="final-price">Отстъпка от ваучер</div>
            <div>
              <Currency number={voucherDiscount(voucher, totalWithDelivery)} />
            </div>
          </div>
        )}

        {shouldHaveCustomerdiscount(customer) && (
          <div className="subtotal-item">
            <div className="final-price">
              Отстъпка специален клиент ({customer.discount_percentage}%)
            </div>
            <div>
              <Currency
                number={customerDiscount(
                  customer,
                  totalWithDelivery - deliveryPrice,
                )}
              />
            </div>
          </div>
        )}

        <div className="subtotal-item">
          <div className="final-price">Общо</div>
          <div>
            <Currency number={totalAmount(voucher, customer, totals)} />
          </div>
        </div>

        {hasDiscount && (
          <div className="success-message">
            При над 2 броя продукт с еднакъв дизайн, предлагаме отстъпки. Наш
            сътрудник ще се свърже с Вас за уточнение, след преглед на
            материалите.
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
