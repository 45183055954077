import React from "react";
import { Checkbox, TextField, TextareaField } from "./Inputs";

const PersonalData = ({ data, customer, errors, onChange }) => (
  <React.Fragment>
    <TextField
      label="Име (поръчител)"
      value={data.name}
      required
      name="order[name]"
      errors={errors.name}
      disabled={!!customer}
      onChange={(name) => onChange("name", name)}
    />

    <div className="form-row">
      <TextField
        label="Ел. поща"
        type="email"
        value={data.email}
        required
        disabled={!!customer}
        name="order[email]"
        errors={errors.email}
        onChange={(email) => onChange("email", email)}
      />
      <TextField
        label="Телефон"
        type="tel"
        value={data.phone}
        required
        disabled={!!customer}
        name="order[phone]"
        errors={errors.phone}
        onChange={(phone) => onChange("phone", phone)}
      />
    </div>

    <div>
      <div className="personal-checkbox">
        <Checkbox
          label="Желая фактура"
          checked={data.invoice}
          name="order[has_invoice]"
          value="1"
          onChange={(checked) => onChange("invoice", checked)}
        />
      </div>

      {data.invoice && (
        <div>
          <div className="form-row">
            <TextareaField
              label="Данни за фактура"
              value={data.invoiceInfo}
              onChange={(newInvoiceInfo) =>
                onChange("invoiceInfo", newInvoiceInfo)
              }
              rows="5"
              name="order[invoice_info]"
              required
            />
          </div>
        </div>
      )}

      <div className="personal-checkbox">
        <Checkbox
          label="Искам да изпратите подаръка направо на получателя (аз ще платя предварително)"
          checked={data.gift}
          name="order[gift]"
          value="1"
          onChange={(checked) => onChange("gift", checked)}
        />
      </div>

      {data.gift && (
        <div className="form-row">
          <TextField
            label="Име (получател)"
            value={data.recipientName}
            name="order[gift_recipient]"
            errors={errors.giftRecipient}
            required
            onChange={(recipientName) =>
              onChange("recipientName", recipientName)
            }
          />
          <TextField
            label="Телефон (получател)"
            type="tel"
            value={data.recipientPhone}
            name="order[gift_recipient_phone]"
            errors={errors.giftRecipientPhone}
            required
            onChange={(recipientPhone) =>
              onChange("recipientPhone", recipientPhone)
            }
          />
        </div>
      )}

      <div className="form-row">
        <TextareaField
          label="Моля, напишете Вашият коментар към поръчката"
          value={data.note}
          onChange={(newInvoiceInfo) => onChange("note", newInvoiceInfo)}
          placeholder="Допълнителни уточнения, които ще ни помогнат за изработване на дизайните за Вашите подаръци..."
          name="order[note]"
        />
      </div>
    </div>
  </React.Fragment>
);

export default PersonalData;
