import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  Container,
  Img,
  List,
  TextInput,
  SelectMenu,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";

const getLightboxImages = (list) =>
  list.map((item) => ({
    src: imageSrc(item.image, "full_width"),
    caption: item.title,
  }));

const GalleryWidget = ({ autoplay, delay, containerSettings, list }) => {
  const [state, setState] = useState({ open: false, index: 0 });

  const openLightbox = (index) => setState({ ...state, open: true, index });
  const autoPlayOpts =
    autoplay === "yes" ? { delay: parseInt(delay, 10) } : false;

  const closeLightbox = () => setState({ ...state, open: false });

  return (
    <Container settings={containerSettings} className="gallery">
      <div className="container">
        <div className="row">
          {list.map((slide, idx) => (
            <div key={slide.id} className="col-3">
              <a className="slide" onClick={() => openLightbox(idx)}>
                <Img loading="lazy" src={slide.image} variant="square" />
              </a>
            </div>
          ))}
        </div>

        <ModalGateway>
          {state.open ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                views={getLightboxImages(list)}
                currentIndex={state.index}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Container>
  );
};

GalleryWidget.title = "Gallery";
GalleryWidget.category = "Media";
GalleryWidget.dialogSize = "large";

GalleryWidget.defaults = {
  autoplay: "no",
  delay: 5000,
  list: [
    {
      id: 1,
      image: imagePlaceholder("1920x1080"),
      title: "Intelliway Systems",
    },
    {
      id: 2,
      image: imagePlaceholder("1920x1080"),
      title: "Intelliway Systems",
    },
    {
      id: 3,
      image: imagePlaceholder("1920x1080"),
      title: "Intelliway Systems",
    },
    {
      id: 4,
      image: imagePlaceholder("1920x1080"),
      title: "Intelliway Systems",
    },
  ],
  containerSettings: { theme: "white" },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />
  </div>
);

GalleryWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Autoplay"
      options={[
        ["no", "No"],
        ["yes", "Yes"],
      ]}
      value={settings.autoplay}
      onChange={(value) => onChange("autoplay", value)}
    />

    <TextInput
      label="Delay"
      onChange={(value) => onChange("delay", value)}
      value={settings.delay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      template={{ image: imagePlaceholder("1920x1080") }}
      items={items}
      primaryField="title"
    />
  </div>
);

export default GalleryWidget;
