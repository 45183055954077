import React from "react";

class CartToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: props.cart || { total: 0, cart_items: [] },
    };
  }

  render() {
    const { cart } = this.state;

    return (
      <div>
        <a href="/store/checkout">
          <i className="icon-card-white"></i>{" "}
          <span className="currency">{cart.items_count}</span>
        </a>
      </div>
    );
  }
}

export default CartToggle;
