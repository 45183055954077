import React from "react";
import PropTypes from "prop-types";
import ProductItem from "./ProductItem";

const ProductFeedSixts = ({ list }) => (
  <div className="container">
    <div className="row">
      {list.map((item) => (
        <ProductItem item={item} key={item.id} className="col-2" />
      ))}
    </div>
  </div>
);

ProductFeedSixts.defaultProps = {
  list: [],
};

ProductFeedSixts.propTypes = {
  list: PropTypes.array,
};

export default ProductFeedSixts;
