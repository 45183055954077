import React from "react";

const OptionItem = ({ index, name, option, onUpdate, onDelete }) => (
  <div className="option-item">
    <input
      type="text"
      name={`${name}[${index}][label]`}
      value={option.label}
      placeholder="Label"
      onChange={(e) => onUpdate("label", e.target.value)}
    />
    <input
      type="text"
      name={`${name}[${index}][value]`}
      value={option.value}
      placeholder="Value"
      onChange={(e) => onUpdate("value", e.target.value)}
    />
    <button type="button" className="btn-danger btn-sm" onClick={onDelete}>
      &times;
    </button>
  </div>
);

const OptionsList = ({ name, items, onUpdate, onDelete }) => (
  <div>
    {items.map((option, idx) => (
      <OptionItem
        key={idx}
        index={idx}
        name={name}
        option={option}
        onUpdate={(key, value) => onUpdate(idx, key, value)}
        onDelete={() => onDelete(idx)}
      />
    ))}
  </div>
);

export default class SelectOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items || [],
    };
  }

  handleAdd() {
    this.setState({
      items: [...this.state.items, { label: "", value: "" }],
    });
  }

  handleUpdate(idx, key, value) {
    const { items } = this.state;
    this.setState({
      items: [
        ...items.slice(0, idx),
        Object.assign({}, items[idx], { [key]: value }),
        ...items.slice(idx + 1),
      ],
    });
  }

  handleDelete(idx) {
    if (!confirm("Are you sure?")) return;

    const { items } = this.state;
    this.setState({
      items: [...items.slice(0, idx), ...items.slice(idx + 1)],
    });
  }

  render() {
    const { name } = this.props;
    const { items } = this.state;

    return (
      <div className="menu-options">
        <h4>Опции (налични за типа меню)</h4>

        {items.length > 0 ? (
          <OptionsList
            items={items}
            name={name}
            onUpdate={(idx, key, value) => this.handleUpdate(idx, key, value)}
            onDelete={(idx) => this.handleDelete(idx)}
          />
        ) : (
          <p>Все още няма опции за това меню. </p>
        )}
        <button
          type="button"
          className="btn-primary btn-sm"
          onClick={() => this.handleAdd()}
        >
          +
        </button>
      </div>
    );
  }
}
