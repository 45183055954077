import React from "react";
import CheckoutSection from "./CheckoutSection";
import { Radio, TextareaField } from "./Inputs";
import { EcontCityPicker, EcontOfficePicker } from "./Pickers";

const AddressField = ({ currentCourier, data, onChange }) => {
  if (
    currentCourier.address_type === "office" &&
    currentCourier.base_courier === "econt"
  ) {
    return (
      <>
        <EcontOfficePicker
          value={data.courierMeta}
          onChange={(office) => onChange("courierMeta", office)}
          econtCityId={data?.cityMeta?.econt_id}
          required
        />

        <input
          type="hidden"
          defaultValue={data?.courierMeta?.address || data.address}
          name="order[address]"
        />

        <input
          type="hidden"
          defaultValue={JSON.stringify({
            type: "econt",
            office: data.courierMeta,
          })}
          name="order[courier_meta]"
        />
      </>
    );
  }

  return (
    <TextareaField
      label={currentCourier.address_type === "office" ? "Офис" : "Адрес"}
      value={data.address}
      name="order[address]"
      required
      onChange={(address) => onChange("address", address)}
    />
  );
};

const DeliveryData = ({ couriers, data, onChange }) => {
  const currentCourier =
    data.method && data.method !== "pickup"
      ? couriers.find((c) => c.id === data.method)
      : null;

  return (
    <React.Fragment>
      <CheckoutSection title="Метод на доставка" variant="lighter">
        <Radio
          label="Ще взема поръчката си от магазин Уникални подаръци (ул. Цар
                  Асен 11, София)"
          value="pickup"
          name="order[courier_id]"
          checked={data.method === "pickup"}
          onChange={(checked) => onChange("method", checked ? "pickup" : "")}
        />

        {couriers.map((courier) => (
          <Radio
            key={courier.id}
            label={courier.label}
            checked={data.method === courier.id}
            value={courier.id}
            name="order[courier_id]"
            onChange={(checked) =>
              onChange("method", checked ? courier.id : "")
            }
          />
        ))}
      </CheckoutSection>

      {currentCourier && (
        <CheckoutSection
          title={`Доставка: ${currentCourier.label}`}
          variant="lighter"
        >
          <EcontCityPicker
            value={data.cityMeta}
            onChange={(city) => onChange("cityMeta", city)}
            required
          />

          <input
            type="hidden"
            name="order[city]"
            defaultValue={data?.cityMeta?.name || data.city || ""}
          />

          <input
            type="hidden"
            name="order[city_meta]"
            defaultValue={JSON.stringify(data.cityMeta || {})}
          />

          <AddressField
            currentCourier={currentCourier}
            data={data}
            onChange={onChange}
          />
        </CheckoutSection>
      )}
    </React.Fragment>
  );
};

export default DeliveryData;
