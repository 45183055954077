import React from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "" || props.value,
    };
  }

  render() {
    const { label, name } = this.props;
    const { value } = this.state;

    const dateProps = {};
    dateProps.selected = value ? moment(value, "YYYY-MM-DD") : "";

    return (
      <div className="form-group">
        <label className="control-label">{label}</label>
        <div className="date-picker">
          <ReactDatePicker
            {...dateProps}
            locale="en-gb"
            dateFormat="DD/MM/YYYY"
            onChange={(date) =>
              this.setState({ value: date.format("YYYY-MM-DD") })
            }
          />
        </div>

        <input type="hidden" name={name} value={value} />
      </div>
    );
  }
}
