import React, { useEffect } from "react";
import { Checkbox, TextField } from "../../Inputs";

const displayError = (error) => {
  if (!error) {
    return false;
  }

  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error)) {
    return error.join(", ");
  }

  return false;
};

const hasError = (errors) =>
  errors.registrationNameError ||
  errors.registrationEmailError ||
  errors.registrationPhoneError ||
  errors.registrationTermsError ||
  errors.registrationPasswordError ||
  errors.registrationPasswordConfirmationError;

const RegistrationForm = ({
  errors,
  registrationName,
  registrationEmail,
  registrationPhone,
  registrationPassword,
  registrationPasswordConfirmation,
  registrationTerms,
  registrationNewsletter,
  onChange,
  onDecline,
  onError,
}) => {
  useEffect(() => {
    if (hasError(errors)) {
      onError("registration-form-general-error-message");
    }
  });

  return (
    <div>
      <h3 className="sub-section-title">Искам да се регистрирам</h3>

      <button type="button" className="btn-text" onClick={onDecline}>
        Назад
      </button>

      <br />
      <br />

      <p className="info-notice text-left">
        <a
          href="/gdpr-policy#lichni-danni"
          target="_blank"
          rel="noopener noreferrer"
        >
          Научете повече
        </a>{" "}
        защо и какви лични данни събираме за поръчка.
      </p>

      <p className="mini-spacing">Полетата отбелязани със * са задължителни</p>

      {hasError(errors) && (
        <div
          id="registration-form-general-error-message"
          className="form-group has-error"
        >
          <small className="help-block">
            Възникнаха грешки, моля разгледайте полетата по-долу.
          </small>
        </div>
      )}

      <TextField
        type="text"
        name="name"
        label="Имена"
        placeholder="Вашите имена (на кирилица)..."
        required
        error={displayError(errors.registrationNameError)}
        value={registrationName}
        onChange={(newRegistrationName) =>
          onChange("registrationName", newRegistrationName)
        }
      />

      <TextField
        type="email"
        name="email"
        label="Email"
        placeholder="Вашият email..."
        required
        error={displayError(errors.registrationEmailError)}
        value={registrationEmail}
        onChange={(newRegistrationEmail) =>
          onChange("registrationEmail", newRegistrationEmail)
        }
      />

      <TextField
        type="text"
        name="phone"
        label="Телефон"
        placeholder="Вашият телефон..."
        required
        error={displayError(errors.registrationPhoneError)}
        value={registrationPhone}
        onChange={(newRegistrationPhone) =>
          onChange("registrationPhone", newRegistrationPhone)
        }
      />

      <TextField
        type="password"
        name="password"
        label="Парола"
        error={displayError(errors.registrationPasswordError)}
        value={registrationPassword}
        required
        onChange={(newRegistrationPassword) =>
          onChange("registrationPassword", newRegistrationPassword)
        }
      />

      <TextField
        type="password"
        name="password_confirmation"
        label="Потвърждение на паролата"
        required
        error={displayError(errors.registrationPasswordConfirmationError)}
        value={registrationPasswordConfirmation}
        onChange={(newRegistrationPasswordConfirmation) =>
          onChange(
            "registrationPasswordConfirmation",
            newRegistrationPasswordConfirmation,
          )
        }
      />

      <Checkbox
        name="terms"
        value="1"
        checked={registrationTerms === true}
        className="terms"
        error={displayError(errors.registrationTermsError)}
        onChange={(e) => onChange("registrationTerms", e.target.checked)}
        required
      >
        Запознат съм с{" "}
        <a href="/terms" target="_blank" rel="noopener noreferrer">
          общите условия
        </a>{" "}
        и с{" "}
        <a href="/gdpr-policy" target="_blank" rel="noopener noreferrer">
          политиката за обработка на лични данни
        </a>
      </Checkbox>

      <Checkbox
        name="newsletter"
        value="1"
        checked={registrationNewsletter === true}
        className="terms"
        onChange={(e) => onChange("registrationNewsletter", e.target.checked)}
      >
        Желая да получавам рекламни известия от Уникални подаръци
      </Checkbox>
    </div>
  );
};

export default RegistrationForm;
