import React from "react";

const TextField = ({
  type,
  required,
  name,
  label,
  placeholder,
  multiline,
  value,
  error,
  onChange,
  onBlur,
}) => (
  <div className={`form-group ${error && "has-error"}`}>
    <label className="control-label" htmlFor={name}>
      {required && <abbr title="задължително">*</abbr>} {label}
    </label>

    {multiline && (
      <textarea
        className="form-control"
        placeholder={placeholder}
        name={name}
        id={name}
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      />
    )}

    {!multiline && (
      <input
        className="form-control"
        placeholder={placeholder}
        type={type}
        name={name}
        id={name}
        required={required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      />
    )}

    {error && <small className="help-block">{error}</small>}
  </div>
);

TextField.defaultProps = {
  type: "text",
  placeholder: "",
  multiline: false,
  required: false,
};

export default TextField;
