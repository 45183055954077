import React from "react";
import { TextField, RadioOption } from "../../Inputs";
import Currency from "../../../../pages/ProductView/Currency";

const CourierForm = ({
  freeDelivery,
  couriers,
  courierId,
  deliveryDescription,
  selectedCourier,
  onChange,
  deliveryAddress,
  deliveryCity,
}) => (
  <div>
    {freeDelivery && (
      <p>Безплатната доставка може да бъде използвана само до ваш адрес. </p>
    )}

    {!freeDelivery && (
      <div>
        <h4>Моля, изберете куриер за доставка:</h4>
        <br />

        <div className="options-list">
          {couriers.map((courier) => (
            <RadioOption
              key={courier.id}
              name="courier"
              value={courier.id}
              checked={parseInt(courierId, 10) === parseInt(courier.id, 10)}
              label={
                <span>
                  {courier.label} – <Currency number={courier.price} />
                </span>
              }
              onChange={(courierId) => onChange("courierId", courierId)}
            />
          ))}
        </div>
      </div>
    )}

    {(courierId > 0 || freeDelivery) && (
      <TextField
        name="address"
        label={
          selectedCourier && selectedCourier.address_type === "office"
            ? "До офис на куриер"
            : "До ваш адрес"
        }
        multiline
        value={deliveryAddress}
        onChange={(address) => onChange("deliveryAddress", address)}
      />
    )}

    {(courierId > 0 || freeDelivery) && (
      <TextField
        name="city"
        label="Град"
        value={deliveryCity}
        onChange={(city) => onChange("deliveryCity", city)}
      />
    )}

    {!freeDelivery && (
      <div dangerouslySetInnerHTML={{ __html: deliveryDescription }} />
    )}
  </div>
);

export default CourierForm;
