import React from "react";
import PropTypes from "prop-types";
import ProductItem from "./ProductItem";

const ProductFeedFifts = ({ list }) => (
  <div className="container">
    <div className="row">
      <div className="shift-1">
        <div className="fifths-row">
          {list.map((item) => (
            <ProductItem className="col-2" item={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

ProductFeedFifts.defaultProps = {
  list: [],
};

ProductFeedFifts.propTypes = {
  list: PropTypes.array,
};

export default ProductFeedFifts;
