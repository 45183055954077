import React, { useState } from "react";
import { isStepValid } from "../../Validations";
import LoginForm from "./LoginForm";
import GuestUserForm from "./GuestUserForm";
import RegistrationForm from "./RegistrationForm";

const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const CustomerStep = (props) => {
  const {
    csrf,
    visibleSection,
    name,
    email,
    phone,
    terms,
    newsletter,
    loginEmail,
    loginPassword,
    registrationName,
    registrationEmail,
    registrationPhone,
    registrationPassword,
    registrationPasswordConfirmation,
    registrationTerms,
    registrationNewsletter,
    errors,
    customer,
    submitUrl,
    loginUrl,
    backUrl,
    onChange,
    onToggle,
    onSubmit,
    onDecline,
  } = props;
  const [isRegistrationInfoVisible, setRegistrationInfoVisible] =
    useState(false);
  const [isWithRegistrationVisible, setWithRegistrationVisible] =
    useState(false);
  const [isNoRegistrationVisible, setNoRegistrationVisible] = useState(false);

  return (
    <div>
      <div className="two-cols client-info">
        <div className="half">
          <div className={visibleSection !== "choice" ? "defocus" : ""}>
            <h3
              className="sub-section-title"
              onClick={() =>
                setRegistrationInfoVisible(!isRegistrationInfoVisible)
              }
            >
              Поръчка без регистрация
            </h3>

            <GuestUserForm
              name={name}
              email={email}
              phone={phone}
              terms={terms}
              newsletter={newsletter}
              onChange={onChange}
              errors={errors}
              isRegistrationInfoVisible={isRegistrationInfoVisible}
              onError={(elementId) => scrollToElement(elementId)}
            />
          </div>
        </div>

        <div className="half">
          {visibleSection === "choice" && (
            <div>
              <h3
                className="sub-section-title"
                onClick={() =>
                  setWithRegistrationVisible(!isWithRegistrationVisible)
                }
              >
                Вече имам регистрация
              </h3>
              <div
                className={`mobile-row ${
                  isWithRegistrationVisible ? "visible" : ""
                }`}
              >
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => onToggle("login")}
                >
                  Вход
                </button>
              </div>

              <h3
                className="sub-section-title sub-section-padding-top"
                onClick={() =>
                  setNoRegistrationVisible(!isNoRegistrationVisible)
                }
              >
                Нямам регистрация, но желая да се регистрирам сега
              </h3>
              <div
                className={`mobile-row ${
                  isNoRegistrationVisible ? "visible" : ""
                }`}
              >
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => onToggle("register")}
                >
                  Регистрация
                </button>
              </div>
            </div>
          )}

          {visibleSection === "login" && (
            <LoginForm
              csrf={csrf}
              loginUrl={loginUrl}
              loginEmail={loginEmail}
              loginPassword={loginPassword}
              onChange={onChange}
              onDecline={() => {
                setRegistrationInfoVisible(!isRegistrationInfoVisible);
                setWithRegistrationVisible(!isWithRegistrationVisible);
                onDecline(visibleSection);
              }}
            />
          )}

          {visibleSection === "register" && (
            <RegistrationForm
              errors={errors}
              registrationName={registrationName}
              registrationEmail={registrationEmail}
              registrationPhone={registrationPhone}
              registrationPassword={registrationPassword}
              registrationPasswordConfirmation={
                registrationPasswordConfirmation
              }
              registrationTerms={registrationTerms}
              registrationNewsletter={registrationNewsletter}
              onChange={onChange}
              onDecline={() => {
                setRegistrationInfoVisible(!isRegistrationInfoVisible);
                setNoRegistrationVisible(!isNoRegistrationVisible);
                onDecline(visibleSection);
              }}
              onError={(elementId) => scrollToElement(elementId)}
            />
          )}
        </div>
      </div>

      <form method="post" onSubmit={onSubmit} action={submitUrl}>
        <input type="hidden" name="authenticity_token" value={csrf} />

        <input type="hidden" name="pre_order[type]" value={visibleSection} />

        <input type="hidden" name="pre_order[guest][name]" value={name} />
        <input type="hidden" name="pre_order[guest][email]" value={email} />
        <input type="hidden" name="pre_order[guest][phone]" value={phone} />
        <input type="hidden" name="pre_order[guest][terms]" value={terms} />
        <input
          type="hidden"
          name="pre_order[guest][newsletter]"
          value={newsletter}
        />

        <input
          type="hidden"
          name="pre_order[registration][name]"
          value={registrationName}
        />
        <input
          type="hidden"
          name="pre_order[registration][email]"
          value={registrationEmail}
        />
        <input
          type="hidden"
          name="pre_order[registration][phone]"
          value={registrationPhone}
        />
        <input
          type="hidden"
          name="pre_order[registration][password]"
          value={registrationPassword}
        />
        <input
          type="hidden"
          name="pre_order[registration][password_confirmation]"
          value={registrationPasswordConfirmation}
        />
        <input
          type="hidden"
          name="pre_order[registration][terms]"
          value={registrationTerms}
        />
        <input
          type="hidden"
          name="pre_order[registration][newsletter]"
          value={registrationNewsletter}
        />

        {visibleSection !== "login" && (
          <div className="btn-step-toolbar">
            <a className="btn-primary btn-step-navigation" href={backUrl}>
              назад
            </a>

            <button
              type="submit"
              className="btn-primary btn-step-navigation"
              disabled={!isStepValid("customer", customer, props)}
            >
              напред
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default CustomerStep;
