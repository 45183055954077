import React from "react";
import { Img } from "@raketa-cms/raketa-cms";
import Currency from "../../pages/ProductView/Currency";
import ProductPersonalizations from "./ProductPersonalizations";

const shouldHaveCustomerdiscount = (customer) =>
  customer && customer.discount_percentage > 0;

const getCustomerDiscount = (customer, total) => {
  if (!customer) return 0;
  if (customer && parseFloat(customer.discount_percentage) === 0) return 0;

  return (parseFloat(total) / 100) * parseFloat(customer.discount_percentage);
};

class Cart extends React.Component {
  renderItems() {
    const { cart, personalizationNames } = this.props;
    const items = cart.cart_items;

    if (items.length === 0)
      return (
        <tr>
          <td colSpan="2">
            Все още няма няма добавени продукти.{" "}
            <a href="/">Добавете продукти</a>
          </td>
        </tr>
      );

    const { onUpdate, onDelete } = this.props;

    return items
      .sort((a, b) => a.id - b.id)
      .map((item) => (
        <tr key={item.id}>
          <td className="item-info">
            <Img src={item.image} variant="thumb" />
            <div>
              {item.product_name}{" "}
              {item.express && (
                <span className="express">(експресна поръчка)</span>
              )}
              <br />
              <ProductPersonalizations
                personalizationNames={personalizationNames}
                personalizations={item.personalizations}
              />
              <button
                type="button"
                className="btn-danger"
                onClick={() => onDelete(item)}
              >
                премахни
              </button>
            </div>
          </td>
          <td style={{ width: "50px" }}>
            <input
              type="text"
              value={item.quantity}
              onChange={(e) => onUpdate(item, e.target.value)}
            />
          </td>
          <td className="number" style={{ width: "100px" }}>
            <Currency number={item.total} />
          </td>
        </tr>
      ));
  }

  render() {
    const { cart, customer } = this.props;

    return (
      <div className="cart">
        <table>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Бройка</th>
              <th className="number">Ед. цена</th>
            </tr>
          </thead>
          <tbody>{this.renderItems()}</tbody>
          {cart.total > 0 && (
            <tfoot>
              {shouldHaveCustomerdiscount(customer) && (
                <tr>
                  <th colSpan="2" className="total">
                    Отстъпка специален клиент ({customer.discount_percentage}%)
                  </th>
                  <td className="number">
                    <Currency
                      number={getCustomerDiscount(customer, cart.total)}
                    />
                  </td>
                </tr>
              )}

              {shouldHaveCustomerdiscount(customer) && (
                <tr>
                  <th colSpan="2" className="total">
                    Преди отстъпка
                  </th>
                  <td className="number">
                    <Currency number={cart.total} />
                  </td>
                </tr>
              )}

              <tr>
                <th colSpan="2" className="total">
                  Общо
                </th>
                <td className="number">
                  <Currency
                    number={
                      cart.total - getCustomerDiscount(customer, cart.total)
                    }
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

export default Cart;
