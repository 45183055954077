import React from "react";
import Currency from "../Currency";
import PersonalizationField from "./PersonalizationField";

const Personalization = ({ personalization, onChange, onToggle }) => (
  <div className="personalization-field">
    {personalization.free ? (
      <label>
        {personalization.mandatory && <abbr title="задължително">*</abbr>}
        {personalization.name}
      </label>
    ) : (
      <label>
        <input
          type="checkbox"
          className="not-included"
          checked={personalization.active}
          onChange={onToggle}
        />{" "}
        {personalization.name}{" "}
        <span className="personalization-price">
          + <Currency number={personalization.price} />
        </span>
      </label>
    )}

    <div
      className={`personalization-field-wrapper ${
        personalization.active ? "" : "hide"
      }`}
    >
      <PersonalizationField
        type={personalization.type}
        customizationId={personalization.customization_id}
        name={`${
          personalization.type === "files"
            ? "personalization_files"
            : "personalizations"
        }[${personalization.customization_id}]`}
        active={personalization.active}
        options={personalization.options}
        value={personalization.value}
        onChange={onChange}
      />
    </div>
  </div>
);

const PersonalizationEditor = ({ personalizations, onChange, onToggle }) => (
  <div className="personalization-editor">
    {personalizations.map((personalization) => (
      <Personalization
        key={personalization.customization_id}
        personalization={personalization}
        onChange={(value) => onChange(personalization.customization_id, value)}
        onToggle={() => onToggle(personalization.customization_id)}
      />
    ))}
  </div>
);

export default PersonalizationEditor;
