import React from "react";
import MCheckIcon from "react-icons/lib/md/check";
import Autocomplete from "./Autocomplete";

const priceAfterDiscount = (price, discount, discountType) => {
  if (discountType === "percentage") {
    return price - price * (discount / 100);
  }

  if (discountType === "absolute") {
    return price - discount;
  }

  throw `"${discountType}" is unknown!`;
};

const pickPrepOnChangeProduct = (product) => {
  if (product.default_preparation) {
    return product.default_preparation.id;
  }

  return null;
};

const Customizations = ({
  product,
  personalizations,
  files,
  onChange,
  onToggle,
}) => {
  if (!product || product.customizations.length === 0) return null;

  const existingPersonalizations = personalizations.map((p) =>
    parseInt(p.customization_id, 10),
  );

  return (
    <div className="customizations-grid">
      <label>Персонализации</label>

      {product.customizations
        .filter((c) =>
          existingPersonalizations.includes(parseInt(c.customization_id, 10)),
        )
        .map((c) => (
          <ProductCustomization
            key={c.customization_id}
            customization={c}
            files={files}
            personalization={personalizations.find(
              (p) =>
                parseInt(p.customization_id) === parseInt(c.customization_id),
            )}
            onChange={(value) => onChange(c.customization_id, value)}
            onToggle={(e) => onToggle(c.customization_id, e.target.checked)}
          />
        ))}
    </div>
  );
};

const ProductCustomization = ({
  customization,
  personalization,
  files,
  onChange,
  onToggle,
}) => (
  <div className="customization-field">
    {customization.free ? (
      <label>
        <MCheckIcon className="icon-included" /> {customization.name}
      </label>
    ) : (
      <label className={`${personalization.active ? "" : "label-disabled"}`}>
        <input
          type="checkbox"
          className="not-included"
          checked={personalization.active}
          onChange={onToggle}
        />{" "}
        {customization.name}{" "}
        {!customization.free ? `+${customization.price} лв.` : ""}
      </label>
    )}

    <CustomizationField
      type={customization.type}
      disabled={!personalization.active}
      files={files}
      options={customization.options}
      value={personalization ? personalization.value : ""}
      onChange={onChange}
    />
  </div>
);

const CustomizationField = ({
  type,
  value,
  disabled,
  options,
  files,
  onChange,
}) => {
  if (type === "text") {
    return (
      <input
        type="text"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }

  if (type === "menu") {
    return (
      <select
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option>Please select...</option>
        {options.map((o, idx) => (
          <option key={idx} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    );
  }

  if (type === "files") {
    if (value === "") return <div>TODO: Качване на снимки</div>;

    const file_ids = JSON.parse(value);
    const customizationFiles = files.filter((f) =>
      file_ids.includes(parseInt(f.id)),
    );

    return (
      <ul>
        {customizationFiles.map((f) => (
          <li key={f.id}>
            <a href={f.asset_url} target="_blank" rel="noopener noreferrer">
              {f.asset_file_name}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  if (type === "checkbox") {
    return <div />;
  }

  return null;
};

export default class CustomizationEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: props.product,
      personalizations: props.personalizations,
      base_price: props.base_price,
      quantity: props.quantity || 1,
      express: props.express || false,
      discount: props.discount,
      discountType: props.discount_type,
      preparationId: props.preparation_id ? props.preparation_id : "",
    };
  }

  getCustomization(customizationId) {
    const { customizations } = this.props;
    return customizations.find(
      (c) => parseInt(c.id, 10) === parseInt(customizationId, 10),
    );
  }

  getPersonalizations(product) {
    return product.customizations.map((c) => ({
      customization_id: c.customization_id,
      type: this.getCustomization(c.customization_id).customization_type,
      active: c.free,
      value: "",
    }));
  }

  handleChangeProduct(selectedProduct) {
    this.setState({
      product: selectedProduct,
      q: selectedProduct.name,
      base_price: selectedProduct.base_price,
      personalizations: this.getPersonalizations(selectedProduct),
      preparationId: pickPrepOnChangeProduct(selectedProduct),
    });
  }

  handleUpdatePersonalization(customization_id, value) {
    const { personalizations } = this.state;
    const updatedIdx = personalizations.findIndex(
      (p) => parseInt(p.customization_id) === parseInt(customization_id),
    );

    const newPersonalizations = [
      ...personalizations.slice(0, updatedIdx),
      Object.assign({}, personalizations[updatedIdx], { value }),
      ...personalizations.slice(updatedIdx + 1),
    ];

    this.setState({ personalizations: newPersonalizations });
  }

  handleToggle(customization_id, checked) {
    const { personalizations } = this.state;
    const personalization = personalizations.find(
      (p) => parseInt(p.customization_id) === parseInt(customization_id),
    );
    const updatedIdx = personalizations.findIndex(
      (p) => parseInt(p.customization_id) === parseInt(customization_id),
    );

    const active = !personalizations[updatedIdx].active;
    const updates =
      personalization.type === "checkbox"
        ? { active, value: checked }
        : { active };

    const newPersonalizations = [
      ...personalizations.slice(0, updatedIdx),
      Object.assign({}, personalizations[updatedIdx], updates),
      ...personalizations.slice(updatedIdx + 1),
    ];

    this.setState({ personalizations: newPersonalizations });
  }

  setExpress(express) {
    this.setState({ express });
  }

  calculatePrice(base_price, product, personalizations, express) {
    return (
      parseFloat(base_price) +
      this.getPersonalizationCosts(personalizations) +
      parseFloat(express ? product.express_price : 0)
    );
  }

  getPersonalizationCosts(personalizations) {
    const { product } = this.state;
    if (!product) return 0;

    const activeCustomizations = personalizations
      .filter((p) => p.active === true)
      .map((p) => parseInt(p.customization_id, 10));
    const { customizations } = product;

    const paidCustomizations = customizations.filter(
      (c) =>
        activeCustomizations.includes(parseInt(c.customization_id, 10)) &&
        c.price > 0,
    );
    if (!paidCustomizations) return 0;

    return paidCustomizations.reduce((sum, c) => sum + parseFloat(c.price), 0);
  }

  render() {
    const { files, discount_types, products_url, preparations } = this.props;
    const { discount, discountType } = this.state;
    const {
      product,
      personalizations,
      base_price,
      quantity,
      express,
      preparationId,
    } = this.state;
    const price = this.calculatePrice(
      base_price,
      product,
      personalizations,
      express,
    );

    return (
      <div className="customization-editor">
        <div className="form-group autocomplete">
          <label className="control-label">Продукт</label>
          <Autocomplete
            searchUrl={products_url}
            searchTermKey="product_info"
            itemToString={(item) => item.name}
            initialSelectedItem={product}
            onSelected={(newProduct) => this.handleChangeProduct(newProduct)}
          />
        </div>

        <Customizations
          product={product}
          personalizations={personalizations}
          files={files}
          onChange={(customization_id, value) =>
            this.handleUpdatePersonalization(customization_id, value)
          }
          onToggle={(customization_id, checked) =>
            this.handleToggle(customization_id, checked)
          }
        />

        <div className="form-group">
          <label className="control-label" htmlFor="line_item_price">
            Ед. цена
          </label>
          <input
            value={base_price}
            onChange={(e) => this.setState({ base_price: e.target.value })}
            className="form-control numeric decimal optional"
            type="number"
            id="line_item_price"
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="line_item_quantity">
            Количество
          </label>
          <input
            name="line_item[quantity]"
            value={quantity}
            onChange={(e) => this.setState({ quantity: e.target.value })}
            className="form-control"
            type="number"
            id="line_item_quantity"
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="line_item_discount">
            Отстъпка
          </label>
          <input
            name="line_item[discount]"
            value={discount}
            onChange={(e) => this.setState({ discount: e.target.value })}
            className="form-control"
            type="number"
            id="line_item_discount"
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="line_item_discount_type">
            Тип отстъпка
          </label>
          <select
            value={discountType}
            onChange={(e) => this.setState({ discountType: e.target.value })}
            className="form-control"
            name="line_item[discount_type]"
            id="line_item_discount_type"
          >
            {discount_types.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <div className="checkbox">
            <input value="0" type="hidden" name="line_item[express]" />
            <label htmlFor="line_item_express">
              <input
                className="boolean optional"
                type="checkbox"
                value="1"
                name="line_item[express]"
                id="line_item_express"
                checked={express}
                onChange={(e) => this.setExpress(e.target.checked)}
              />
              Експресна
            </label>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="line_item_discount_type">
            Изработка при
          </label>
          <select
            value={preparationId}
            onChange={(e) => this.setState({ preparationId: e.target.value })}
            className="form-control"
            name="line_item[preparation_id]"
            id="line_item_preparation_id"
          >
            <option value="">Изберете...</option>
            {preparations.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </select>
        </div>

        <h3>
          Единична цена: {priceAfterDiscount(price, discount, discountType)} лв.
        </h3>

        <input
          type="hidden"
          name="line_item[personalizations]"
          defaultValue={JSON.stringify(personalizations)}
        />
        <input type="hidden" name="line_item[price]" value={price} />
        <input
          type="hidden"
          name="line_item[product_id]"
          defaultValue={product ? product.id : 0}
        />
        <input
          type="hidden"
          name="line_item[preparation_id]"
          defaultValue={preparationId}
        />
      </div>
    );
  }
}
