const validCart = ({ cart }) => cart.cart_items.length > 0;

const validDelivery = ({
  freeDelivery,
  deliveryMethod,
  deliveryAddress,
  deliveryCity,
  courierId,
}) => {
  if (deliveryMethod === "pickup") {
    return true;
  }

  if (
    freeDelivery &&
    deliveryMethod === "courier" &&
    deliveryAddress &&
    deliveryCity
  ) {
    return true;
  }

  return (
    !freeDelivery &&
    deliveryMethod === "courier" &&
    courierId > 0 &&
    deliveryAddress &&
    deliveryCity
  );
};

const validAnonymous = ({ name, email, phone, terms }) =>
  name !== "" && email !== "" && phone !== "" && terms;

const validRegistration = ({
  registrationTerms,
  registrationName,
  registrationEmail,
  registrationPhone,
  registrationPassword,
  registrationPasswordConfirmation,
}) =>
  registrationName !== "" &&
  registrationEmail !== "" &&
  registrationPhone !== "" &&
  registrationPassword !== "" &&
  registrationPasswordConfirmation !== "" &&
  registrationTerms;

const validCustomer = (customer, params) => {
  if (customer) {
    return true;
  }

  const { visibleSection } = params;

  if (visibleSection === "choice") {
    return validAnonymous(params);
  }

  if (visibleSection === "register") {
    return validRegistration(params);
  }

  return false;
};

const isStepValid = (currentStep, customer, params) => {
  if (currentStep === "cart") {
    return validCart(params);
  }
  if (currentStep === "customer") {
    return validCustomer(customer, params);
  }
  if (currentStep === "delivery") {
    return validDelivery(params);
  }
  if (currentStep === "summary") return true;

  return false;
};

export default isStepValid;
