import React from "react";
import Autocomplete from "../../fields/Autocomplete";

const EcontCityPicker = ({ value, onChange, ...rest }) => (
  <div>
    <label className="control-label">Град</label>

    <Autocomplete
      searchUrl="/api/econt_cities"
      searchTermKey="keyword"
      placeholder="Търси..."
      itemToString={(item) =>
        item.name ? `${item.name} (${item.region})` : ""
      }
      initialSelectedItem={value}
      onSelected={(newCity) => onChange(newCity)}
      {...rest}
    />
  </div>
);

const EcontOfficePicker = ({ value, econtCityId, onChange, ...rest }) => (
  <div>
    <label className="control-label">Офис</label>

    <Autocomplete
      searchUrl="/api/econt_offices"
      searchTermKey="keyword"
      placeholder="Търси..."
      itemToString={(item) =>
        item.office_code && item.address
          ? `${item.office_code} - ${item.name} - ${item.address}`
          : ""
      }
      initialSelectedItem={value}
      additionalSearchTerms={
        econtCityId ? { "f[econt_city_id]": econtCityId } : {}
      }
      onSelected={(newOffice) => onChange(newOffice)}
      {...rest}
    />
  </div>
);

export { EcontCityPicker, EcontOfficePicker };
