import React from "react";
import PropTypes from "prop-types";

import LIBRARY from "./widgets";

const PageRender = ({ page }) => {
  return (
    <React.Fragment>
      {page.widgets.map((widget, idx) =>
        React.createElement(
          LIBRARY[widget.component],
          Object.assign({}, { key: idx }, widget.settings),
        ),
      )}
    </React.Fragment>
  );
};

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
