import React from "react";
import Step from "../Step";
import PreviewStep from "./PreviewStep";

const isDeliveryFree = (cart, voucher) => {
  if (voucher && voucher.free_delivery === true) {
    return true;
  }

  if (cart) {
    return cart.cart_items.some((ci) => ci.product_free_delivery === true);
  }

  return false;
};

const getVoucherDiscount = (voucher, totalWithDelivery) => {
  if (voucher.discount_type === "fixed") return voucher.discount;
  if (voucher.discount_type === "percent")
    return (
      (parseFloat(totalWithDelivery) / 100) * parseInt(voucher.discount, 10)
    );

  return 0;
};

const getCustomerDiscount = (customer, total) => {
  return (parseFloat(total) / 100) * parseFloat(customer.discount_percentage);
};

const cartHasDiscount = (cart) =>
  cart.cart_items
    .filter((i) => i.personalizations.length > 0)
    .filter((i) => i.quantity > 2).length > 0;

const getCourier = (couriers, courierId) =>
  couriers.find((c) => parseInt(c.id, 10) === parseInt(courierId, 10));

const getDeliveryPrice = (selectedCourier, cart, voucher) => {
  if (selectedCourier) {
    return isDeliveryFree(cart, voucher)
      ? 0
      : parseFloat(selectedCourier.price);
  }

  return 0;
};

const orderTotals = ({
  cart,
  deliveryMethod,
  couriers,
  courierId,
  voucher,
}) => {
  const selectedCourier = getCourier(couriers, courierId);
  const deliveryPrice = getDeliveryPrice(selectedCourier, cart, voucher);
  const cartTotal = parseFloat(cart.total);
  const totalWithDelivery =
    deliveryMethod === "courier" ? cartTotal + deliveryPrice : cartTotal;

  return {
    deliveryPrice,
    cartTotal,
    totalWithDelivery,
  };
};

const Checkout = ({
  csrf,
  cart,
  submitUrl,
  backUrl,
  personalizationNames,
  customer,
  hasInvoice,
  discountCode,
  invoiceInfo,
  note,
  cartFilesSize,
  city,
  address,
  deliveryMethod,
  courierId,
  customerType,
  guest,
  registration,
  voucher,
  couriers,
}) => {
  const { name, email, phone, terms, newsletter } = guest;
  const {
    name: registrationName,
    email: registrationEmail,
    phone: registrationPhone,
    password: registrationPassword,
    password_confirmation: registrationPasswordConfirmation,
    newsletter: registrationNewsletter,
    terms: registrationTerms,
  } = registration;

  const freeDelivery = isDeliveryFree(cart, voucher);
  const hasDiscount = cartHasDiscount(cart);

  const selectedCourier = getCourier(couriers, courierId);

  const { deliveryPrice, totalWithDelivery } = orderTotals({
    cart,
    deliveryMethod,
    couriers,
    courierId,
    voucher,
  });

  return (
    <div className="checkout">
      <Step
        title="Преглед на Вашата поръчка"
        step="preview"
        active
        showCustomer={!customer}
      >
        <PreviewStep
          cart={cart}
          visibleSection={customerType}
          note={note}
          cartFilesSize={cartFilesSize}
          name={name}
          email={email}
          phone={phone}
          terms={terms}
          newsletter={newsletter}
          deliveryMethod={deliveryMethod}
          registrationName={registrationName}
          registrationEmail={registrationEmail}
          registrationPhone={registrationPhone}
          registrationPassword={registrationPassword}
          registrationPasswordConfirmation={registrationPasswordConfirmation}
          registrationNewsletter={registrationNewsletter}
          registrationTerms={registrationTerms}
          hasInvoice={hasInvoice}
          invoiceInfo={invoiceInfo}
          discountCode={discountCode}
          voucher={voucher}
          hasDiscount={hasDiscount}
          selectedCourier={selectedCourier}
          deliveryPrice={deliveryPrice}
          totalWithDelivery={totalWithDelivery}
          freeDelivery={freeDelivery}
          courierId={courierId}
          deliveryAddress={address}
          deliveryCity={city}
          csrf={csrf}
          submitUrl={submitUrl}
          backUrl={backUrl}
          customer={customer}
          personalizationNames={personalizationNames}
          getVoucherDiscount={(voucher, totalWithDelivery) =>
            getVoucherDiscount(voucher, totalWithDelivery)
          }
          getCustomerDiscount={(customer, total) =>
            getCustomerDiscount(customer, total)
          }
        />
      </Step>
    </div>
  );
};

export default Checkout;
