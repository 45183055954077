import React from "react";
import PropTypes from "prop-types";
import {
  Img,
  Container,
  List,
  TextInput,
  TextArea,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import Slider from "../Slider";

const SliderWidget = ({ list, containerSettings }) => (
  <Container className="slider" settings={containerSettings}>
    <Slider
      pagination={{
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      }}
      paginationClickable
    >
      {list.map((slide) => (
        <div key={slide.id}>
          <Img
            src={slide.image}
            variant="span_three"
            className="desktop-image"
          />
          <Img src={slide.image} variant="square" className="mobile-image" />

          <div className="text">
            <div className="text-inner">
              <h1 className="title">{slide.title}</h1>
              <h4 className="description">{slide.description}</h4>
              <a href={slide.buttonUrl} className="btn-primary">
                {slide.buttonLabel}
              </a>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </Container>
);

SliderWidget.title = "Slider";
SliderWidget.category = "Media";
SliderWidget.dialogSize = "large";

SliderWidget.defaultProps = {
  list: [],
};

SliderWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

SliderWidget.defaults = {
  list: [
    {
      id: 1,
      image: imagePlaceholder("1564x500"),
      title: "H1 REGULAR ALL CAPS",
      description:
        "regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
      buttonLabel: "Button",
      buttonUrl: "#",
    },
    {
      id: 2,
      image: imagePlaceholder("1564x500"),
      title: "H1 REGULAR ALL CAPS",
      description:
        "regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
      buttonLabel: "Button",
      buttonUrl: "#",
    },
    {
      id: 3,
      image: imagePlaceholder("1564x500"),
      title: "H1 REGULAR ALL CAPS",
      description:
        "regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
      buttonLabel: "Button",
      buttonUrl: "#",
    },
  ],
  containerSettings: {},
};

SliderWidget.adminFields = {
  image: { type: "custom", component: ImagePicker },
  description: { type: "text" },
};

const SlideFields = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextArea
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <TextInput
      label="Button Label"
      onChange={(value) => onChangeItem("buttonLabel", value)}
      value={settings.buttonLabel}
    />

    <TextInput
      label="Button URL"
      onChange={(value) => onChangeItem("buttonUrl", value)}
      value={settings.buttonUrl}
    />
  </div>
);

SlideFields.defaultProps = {
  settings: {},
};

SlideFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

SliderWidget.adminFields = (items, onChange) => (
  <List
    listItem={(settings, onChangeItem) => (
      <SlideFields settings={settings} onChangeItem={onChangeItem} />
    )}
    onChangeList={onChange}
    items={items}
    titleField="title"
    template={[
      {
        image: imagePlaceholder("1564x500"),
        title: "H1 REGULAR ALL CAPS",
        description:
          "regular важи и за еднакви и за различни чаши. Офертата е валидна за поръчки получени от 10:00 часа на 20.02.2017 до 23:59 часа на 24.02.2017г. или до изчерпване на количеството оферти.",
        buttonLabel: "Button",
        buttonUrl: "#",
      },
    ]}
    primaryField="title"
  />
);

export default SliderWidget;
