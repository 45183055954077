import React from "react";
import { TextField, Checkbox } from "../../Inputs";
import { isStepValid } from "../../Validations";
import Cart from "../../Cart";

const CartStep = ({
  personalizationNames,
  cart,
  hasDiscount,
  discountCode,
  voucherMessage,
  hasInvoice,
  invoiceInfo,
  note,
  onCartUpdateProduct,
  onCartDeleteProduct,
  onChange,
  onDiscountCodeApply,
  customer,
  csrf,
  submitUrl,
}) => (
  <div>
    <Cart
      personalizationNames={personalizationNames}
      cart={cart}
      customer={customer}
      onUpdate={(item, quantity) => onCartUpdateProduct(item, quantity)}
      onDelete={(item) => onCartDeleteProduct(item)}
    />

    {hasDiscount && (
      <div className="discount-note">
        При над 2 броя продукт с еднакъв дизайн, предлагаме отстъпки. Наш
        сътрудник ще се свърже с Вас за уточнение, след преглед на материалите.
      </div>
    )}

    <br />

    <div className="discount-code">
      <TextField
        name="discount_code"
        label="Код за отстъпка"
        value={discountCode}
        onChange={(discountCode) => onChange("discountCode", discountCode)}
      />

      <button
        type="button"
        className="btn-primary"
        onClick={() => onDiscountCodeApply()}
      >
        Приложи
      </button>

      {voucherMessage && (
        <div className="voucher-message">{voucherMessage}</div>
      )}
    </div>

    <Checkbox
      name="hasInvoice"
      value={hasInvoice}
      checked={hasInvoice === true}
      label="Желая фактура"
      onChange={(e) => onChange("hasInvoice", e.target.checked)}
    />

    <div className={hasInvoice ? "" : "hide"}>
      <TextField
        name="invoice_info"
        label="Данни за фактура"
        placeholder="Фирма, Булстат, Адрес..."
        multiline
        value={invoiceInfo}
        onChange={(invoiceInfo) => onChange("invoiceInfo", invoiceInfo)}
      />
    </div>

    <TextField
      name="note"
      label="Моля, напишете Вашият коментар към поръчката"
      placeholder="Допълнителни уточнения, които ще ни помогнат за изработване на дизайните за Вашите подаръци..."
      multiline
      value={note}
      onChange={(newNote) => onChange("note", newNote)}
    />

    <form method="post" action={submitUrl}>
      <input type="hidden" name="authenticity_token" value={csrf} />

      <input type="hidden" name="pre_order[note]" value={note} />
      <input type="hidden" name="pre_order[invoice_info]" value={invoiceInfo} />
      <input type="hidden" name="pre_order[has_invoice]" value={hasInvoice} />
      <input
        type="hidden"
        name="pre_order[discount_code]"
        value={discountCode}
      />

      <div className="btn-step-toolbar">
        <button
          type="submit"
          className="btn-primary btn-step-navigation"
          disabled={!isStepValid("cart", customer, { cart })}
        >
          напред
        </button>
      </div>
    </form>
  </div>
);

export default CartStep;
