import React from "react";
import jQuery from "jquery";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import { MediaManagerContext } from "@raketa-cms/raketa-image-picker";

import LIBRARY from "./widgets";
import MediaManager from "./fields/ImageInput/MediaManager";

const THEMES = [
  ["white", "White"],
  ["light", "Light"],
  ["dark", "Dark"],
];

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mediaManager = new MediaManager("/");

const AdminBuilder = ({ page: defaultPage, host, back_url, save_url }) => {
  const [dirty, setDirty] = React.useState(false);
  const [page, setPage] = React.useState(defaultPage);

  const handleChange = (page) => {
    setPage(page);
    setDirty(true);
  };

  const handleSave = (page) => {
    jQuery.ajax({
      method: "PATCH",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          jQuery('meta[name="csrf-token"]').attr("content"),
        );
      },
      contentType: "application/json; charset=utf-8",
      url: save_url,
      data: JSON.stringify({ widgets: page.widgets }),
      dataType: "json",
      success: (resp) => {
        setDirty(false);
      },
      error: (xhr, err, e) => {
        alert("An error occured saving this page. ");
      },
    });
  };

  return (
    <ErrorBoundary>
      <MediaManagerContext.Provider value={mediaManager}>
        <div className="widgets-spacings-reset">
          <PageBuilder
            host={host}
            dirty={dirty}
            library={LIBRARY}
            adminLibrary={LIBRARY}
            themes={THEMES}
            page={page}
            onChange={handleChange}
            onSave={handleSave}
            onExit={() => (window.location.href = back_url)}
            identifier="unikalni"
          />
        </div>
      </MediaManagerContext.Provider>
    </ErrorBoundary>
  );
};

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
