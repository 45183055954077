import React from "react";
import MdSearchIcon from "react-icons/lib/md/search";
import MdReportIcon from "react-icons/lib/md/report";
import DialogWrapper from "../DialogWrapper";

import { TextInput, Dialog, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const Errors = ({ messages }) => {
  if (messages.length === 0) return null;

  return (
    <ul className="error-messages">
      {messages.map((error, idx) => (
        <li key={idx}>{error}</li>
      ))}
    </ul>
  );
};

export default class CustomerPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      customer: props.customer || { id: "" },
      errors: [],
      q: "",
      customers: [],
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      isOpenCreate: false,
    };
  }

  handleSearch(q) {
    this.setState({ q, customers: [] }, () => {
      if (q.length < 3) return;

      $.get("/cms/customers.json", { f: { customer_info: q } }, (customers) => {
        this.setState({ customers });
      });
    });
  }

  handleCreate() {
    const customer = {
      name: this.state.customerName,
      email: this.state.customerEmail,
      phone: this.state.customerPhone,
    };

    $.ajax({
      method: "POST",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content"),
        );
      },
      url: "/cms/customers",
      data: { customer },
      dataType: "json",
      success: (resp) => {
        if (resp.hasOwnProperty("errors")) {
          this.setState({ errors: resp.errors });
          return;
        }

        this.setState({
          errors: [],
          customer: resp,
          isOpenCreate: false,
          isOpen: false,
          q: "",
          customerName: "",
          customerEmail: "",
          customerPhone: "",
        });
      },
    });
  }

  renderCustomers() {
    const { customers } = this.state;
    if (customers.length === 0)
      return (
        <tr>
          <td colSpan="3" className="no-results">
            Няма намерени клиенти.{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ isOpenCreate: true });
              }}
            >
              Създаване на нов клиент
            </a>
          </td>
        </tr>
      );

    return customers.map((customer) => (
      <tr
        key={customer.id}
        className="customer-result"
        onClick={() => this.setState({ customer, isOpen: false })}
      >
        <td>
          {customer.name}{" "}
          {customer.risk ? (
            <a href="#" title={customer.note}>
              <MdReportIcon />
            </a>
          ) : null}
        </td>
        <td>{customer.phone}</td>
        <td>{customer.email}</td>
      </tr>
    ));
  }

  render() {
    const {
      isOpen,
      customer,
      errors,
      q,
      isOpenCreate,
      customerName,
      customerEmail,
      customerPhone,
    } = this.state;
    const { name } = this.props;

    return (
      <RaketaUIProvider>
        <div className="customer-picker">
          <label htmlFor="">Клиент</label>

          <div
            className="customer-label"
            onClick={() => this.setState({ isOpen: true })}
          >
            <span className="search-icon">
              <MdSearchIcon />
            </span>
            {customer.id > 0
              ? customer.name
              : "Търсене, или добавяне на клиент"}
          </div>

          <DialogWrapper open={isOpen}>
            <Dialog
              open={isOpen}
              title="Търсене, или добавяне на клиент"
              primaryLabel="Затваряне"
              secondaryLabel={false}
              width="700px"
              onPrimary={() => this.setState({ isOpen: false })}
              onClose={() => this.setState({ isOpen: false })}
            >
              <input
                type="text"
                value={q}
                onChange={(e) => this.handleSearch(e.target.value)}
                placeholder="Име, ел. поща, или телефон"
              />

              <table className="client-list">
                <thead>
                  <tr>
                    <th>Име</th>
                    <th>Телефон</th>
                    <th>Ел. поща</th>
                  </tr>
                </thead>
                <tbody>{this.renderCustomers()}</tbody>
              </table>
            </Dialog>
          </DialogWrapper>

          <DialogWrapper open={isOpenCreate}>
            <Dialog
              open={isOpenCreate}
              title="Нов клиент"
              primaryLabel="Запазване"
              secondaryLabel={false}
              width="400px"
              onPrimary={() => this.handleCreate()}
              onClose={() => this.setState({ isOpenCreate: false })}
            >
              <Errors messages={errors} />
              <TextInput
                label="Име"
                value={customerName}
                onChange={(customerName) => this.setState({ customerName })}
              />
              <TextInput
                label="Ел. поща"
                value={customerEmail}
                onChange={(customerEmail) => this.setState({ customerEmail })}
              />
              <TextInput
                label="Телефон"
                value={customerPhone}
                onChange={(customerPhone) => this.setState({ customerPhone })}
              />
            </Dialog>
          </DialogWrapper>

          <input type="hidden" name={name} value={customer.id} />
        </div>
      </RaketaUIProvider>
    );
  }
}
