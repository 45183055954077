import React, { useEffect } from "react";
import { TextField, Checkbox } from "../../Inputs";

const displayError = (error) => {
  if (!error) {
    return false;
  }

  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error)) {
    return error.join(", ");
  }

  return false;
};

const hasError = (errors) =>
  errors.nameError ||
  errors.emailError ||
  errors.phoneError ||
  errors.termsError;

const GuestUserForm = ({
  name,
  email,
  phone,
  terms,
  newsletter,
  onChange,
  errors,
  isRegistrationInfoVisible,
  onError,
}) => {
  useEffect(() => {
    if (hasError(errors)) {
      onError("guest-form-general-error-message");
    }
  });

  return (
    <div className={`mobile-row ${isRegistrationInfoVisible ? "visible" : ""}`}>
      <p className="info-notice text-left">
        <a
          href="/gdpr-policy#lichni-danni"
          target="_blank"
          rel="noopener noreferrer"
        >
          Научете повече
        </a>{" "}
        защо и какви лични данни събираме за поръчка.
      </p>

      {hasError(errors) && (
        <div
          id="guest-form-general-error-message"
          className="form-group has-error"
        >
          <small className="help-block">
            Възникнаха грешки, моля разгледайте полетата по-долу.
          </small>
        </div>
      )}

      <TextField
        name="name"
        label="Имена"
        placeholder="Вашите имена..."
        required
        error={displayError(errors.nameError)}
        value={name}
        onChange={(name) => onChange("name", name)}
      />

      <TextField
        type="email"
        name="email"
        label="Email"
        placeholder="Вашият email..."
        required
        error={displayError(errors.emailError)}
        value={email}
        onChange={(email) => onChange("email", email)}
      />

      <TextField
        name="phone"
        label="Телефон"
        placeholder="Вашият телефон..."
        required
        error={displayError(errors.phoneError)}
        value={phone}
        onChange={(phone) => onChange("phone", phone)}
      />

      <Checkbox
        name="terms"
        value="1"
        checked={terms === true}
        className="terms"
        error={displayError(errors.termsError)}
        onChange={(e) => onChange("terms", e.target.checked)}
        required
      >
        Запознат съм с{" "}
        <a href="/terms" target="_blank" rel="noopener noreferrer">
          общите условия
        </a>{" "}
        и с{" "}
        <a href="/gdpr-policy" target="_blank" rel="noopener noreferrer">
          политиката за обработка на лични данни
        </a>
      </Checkbox>

      <Checkbox
        name="newsletter"
        value="1"
        checked={newsletter === true}
        className="terms"
        onChange={(e) => onChange("newsletter", e.target.checked)}
      >
        Желая да получавам рекламни известия от Уникални подаръци
      </Checkbox>
    </div>
  );
};

export default GuestUserForm;
