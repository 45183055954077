import React from "react";

const LineItem = ({ item, onChange, onDelete }) => (
  <div className="spacing-top-sm">
    <div className="cart-sections">
      <img
        src={(item.image && item.image.urls && item.image.urls.thumb) || ""}
        className="product-thumbnail"
      />

      <div className="line-item-info">
        <h5>
          {item.product_name}{" "}
          {item.express && <span className="express">(експресна поръчка)</span>}
        </h5>

        <div className="actions">
          <div className="count-actions">
            <div>{item.quantity} бр.</div>

            <button
              type="button"
              className="add-remove-btn"
              onClick={() => onChange({ ...item, quantity: item.quantity + 1 })}
            >
              +
            </button>

            {item.quantity > 1 && (
              <button
                type="button"
                className="add-remove-btn"
                onClick={() =>
                  onChange({ ...item, quantity: item.quantity - 1 })
                }
              >
                -
              </button>
            )}

            {item.quantity === 1 && (
              <button
                type="button"
                className="add-remove-btn"
                onClick={() => {
                  if (
                    confirm(
                      "Сигурни ли сте, че искате да премахнете този продукт от количката?",
                    )
                  ) {
                    onDelete(item);
                  }
                }}
              >
                &times;
              </button>
            )}
          </div>

          <div>{item.total} лв.</div>
        </div>
      </div>
    </div>
  </div>
);

const CheckoutCart = ({ cart, onChangeItem, onDeleteItem }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="checkout-section monochrome checkout-cart">
      <button
        type="button"
        className="cart-title"
        onClick={() => setOpen(!open)}
      >
        <h4>Кошница</h4>

        <div className={`icon ${open ? "up-arrow" : "down-arrow"}`}>
          Toggle cart
        </div>
      </button>

      {open && (
        <div className="fields">
          {cart.cart_items
            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
            .map((item) => (
              <LineItem
                key={item.id}
                item={item}
                onChange={onChangeItem}
                onDelete={onDeleteItem}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default CheckoutCart;
