import React from "react";
import { TextField } from "../../Inputs";
import { ValidateEmail } from "../../Validations";

const LoginForm = ({
  csrf,
  loginUrl,
  loginEmail,
  loginPassword,
  onChange,
  onDecline,
}) => (
  <form method="post" action={loginUrl}>
    <h3 className="sub-section-title">Вече имам профил</h3>

    <TextField
      type="email"
      name="email"
      label="Email"
      error={
        loginEmail !== "" && !ValidateEmail(loginEmail)
          ? "Моля въведете валиден e-mail адрес"
          : false
      }
      value={loginEmail}
      onChange={(newLoginEmail) => onChange("loginEmail", newLoginEmail)}
    />
    <TextField
      type="password"
      name="password"
      label="Парола"
      value={loginPassword}
      onChange={(newLoginPassword) =>
        onChange("loginPassword", newLoginPassword)
      }
    />

    <input type="hidden" name="back" value="checkout" />
    <input type="hidden" name="authenticity_token" value={csrf} />

    <div className="btn-step-toolbar">
      <button
        type="button"
        className="btn-secondary btn-step-navigation"
        onClick={onDecline}
      >
        Назад
      </button>

      <button type="submit" className="btn-primary">
        Вход
      </button>
    </div>
  </form>
);

export default LoginForm;
