import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";

const SeparatorWidget = ({ containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <div className="col-12 separator-wigdet">
          <hr />
        </div>
      </div>
    </div>
  </Container>
);

SeparatorWidget.title = "Separator";
SeparatorWidget.category = "General";

SeparatorWidget.propTypes = {
  containerSettings: PropTypes.object.isRequired,
};

SeparatorWidget.defaults = {
  containerSettings: {},
};

SeparatorWidget.adminFields = {};

export default SeparatorWidget;
