import React from "react";
import PropTypes from "prop-types";

const ConditionalElement = ({
  tagName,
  condition,
  attributes,
  childrenElements,
}) => {
  if (condition)
    return React.createElement(tagName, { ...attributes }, childrenElements);

  return childrenElements;
};

ConditionalElement.propTypes = {
  condition: PropTypes.bool.isRequired,
  tagName: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  childrenElements: PropTypes.node.isRequired,
};

export default ConditionalElement;
