import React from "react";
import { RadioOption } from "../../Inputs";
import { isStepValid } from "../../Validations";
import CourierForm from "./CourierForm";

const DeliveryStep = (props) => {
  const {
    customer,
    csrf,
    deliveryMethod,
    freeDelivery,
    couriers,
    courierId,
    deliveryDescription,
    selectedCourier,
    onChange,
    onCourierDelivery,
    deliveryAddress,
    deliveryCity,
    backUrl,
    submitUrl,
  } = props;

  return (
    <div>
      <div className="two-cols">
        <div className="half">
          <h3 className="sub-section-title">Начин на доставка</h3>

          <RadioOption
            name="delivery_method"
            value="pickup"
            checked={deliveryMethod === "pickup"}
            label="Ще взема поръчката си от магазин Уникални подаръци"
            onChange={(deliveryMethod) =>
              onChange("deliveryMethod", deliveryMethod)
            }
          />

          <RadioOption
            name="delivery_method"
            value="courier"
            checked={deliveryMethod === "courier"}
            label="Искам доставка с куриер"
            onChange={(deliveryMethod) => onCourierDelivery(deliveryMethod)}
          />
        </div>

        <div className="half">
          <h3 className="sub-section-title">Информация за доставката</h3>

          {deliveryMethod === "pickup" && (
            <div>
              <strong>Магазин Уникални Подаръци</strong>
              <br />
              ул. Цар Асен 11, София
            </div>
          )}

          {deliveryMethod === "courier" && (
            <CourierForm
              freeDelivery={freeDelivery}
              couriers={couriers}
              courierId={courierId}
              deliveryDescription={deliveryDescription}
              selectedCourier={selectedCourier}
              onChange={onChange}
              deliveryAddress={deliveryAddress}
              deliveryCity={deliveryCity}
            />
          )}
        </div>
      </div>

      <form method="post" action={submitUrl}>
        <input type="hidden" name="authenticity_token" value={csrf} />

        <input type="hidden" name="pre_order[city]" value={deliveryCity} />
        <input
          type="hidden"
          name="pre_order[address]"
          value={deliveryAddress}
        />
        <input
          type="hidden"
          name="pre_order[delivery_method]"
          value={deliveryMethod}
        />
        <input type="hidden" name="pre_order[courier_id]" value={courierId} />

        <div className="btn-step-toolbar">
          <a className="btn-primary btn-step-navigation" href={backUrl}>
            назад
          </a>

          <button
            type="submit"
            className="btn-primary btn-step-navigation"
            disabled={!isStepValid("delivery", customer, props)}
          >
            напред
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeliveryStep;
