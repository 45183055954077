import React from "react";
import { Radio } from "./Inputs";

const label = (method) =>
  ({
    card: "С карта (ще получите линк за плащане с карта след уточнение на поръчката)",
    on_delivery: "При получаване",
  }[method]);

const PaymentData = ({ data, paymentMethods, onlyOnDelivery, onChange }) =>
  paymentMethods.map((paymentMethod) => (
    <Radio
      key={paymentMethod}
      label={label(paymentMethod)}
      value={paymentMethod}
      name="order[payment_method]"
      checked={data.method === paymentMethod}
      disabled={onlyOnDelivery && paymentMethod !== "on_delivery"}
      onChange={(checked) => onChange("method", checked ? paymentMethod : "")}
    />
  ));

export default PaymentData;
