import React from "react";
import { Img } from "@raketa-cms/raketa-cms";
import Personalizations from "./Personalizations";
import Currency from "./Currency";
import SimpleLayout from "./Layout/SimpleLayout";
import SimpleCol from "./Layout/SimpleCol";
import buildPersonalization from "./Personalizations/buildPersonalization";
import Gallery from "./Gallery";
import ProductVideo from "./ProductVideo";
import DetailsNavigation from "./DetailsNavigation";
import Slider from "../../Slider";

const CartButton = ({ name, value, label, note, disabled }) => (
  <button
    type="submit"
    name={name}
    value={value}
    disabled={disabled}
    className="cart-button"
  >
    <span className="label">{label}</span>
    <span className="note">{note}</span>
  </button>
);

const Steper = ({ value, onChange }) => (
  <div className="steper">
    <button
      type="button"
      onClick={() => {
        if (value > 1) onChange(parseInt(value, 10) - 1);
      }}
    >
      -
    </button>
    <input
      type="text"
      min="1"
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
    />
    <button type="button" onClick={() => onChange(parseInt(value, 10) + 1)}>
      +
    </button>
  </div>
);

const validateTotalFileSize = (personalizations) => {
  const errors = [];
  const maxSize = 15 * 1024 * 1024;

  let totalFilesSize = 0;

  personalizations.forEach((p) => {
    if (p.type === "files") {
      const fields = document.querySelectorAll(`#files_${p.customization_id}`);

      for (let j = 0, fieldsLen = fields.length; j < fieldsLen; j++) {
        const field = fields[j];

        for (let i = 0, filesLen = field.files.length; i < filesLen; i++) {
          const file = field.files[i];

          totalFilesSize += file.size;
        }
      }
    }
  });

  if (totalFilesSize > maxSize) {
    errors.push(
      "Общия размер файлове надхвърля 15 MB. Моля изберете снимките, така че да се вписват в това ограничение или изпратете поръчката си на unikalni.podaraci@gmail.com",
    );
  }

  return errors;
};

const validateFiles = (personalizations) => {
  const errors = [];
  const maxSize = 5 * 1024 * 1024;

  personalizations.forEach((p) => {
    if (p.type === "files") {
      const fields = document.querySelectorAll(`#files_${p.customization_id}`);

      for (let j = 0, fieldsLen = fields.length; j < fieldsLen; j++) {
        const field = fields[j];

        if (field.files.length > 0) {
          const invalidFiles = [];

          for (let i = 0, len = field.files.length; i < len; i++) {
            const file = field.files[i];

            if (file.size >= maxSize) invalidFiles.push(file);
          }

          if (invalidFiles.length > 0) {
            errors.push(
              [
                p.name,
                ": Следните файлове са над позволения размер: ",
                invalidFiles.map((f) => f.name).join(", "),
                ". Моля, изберете снимки до 5MB всяка една или изпратете поръчката си на unikalni.podaraci@gmail.com",
              ].join(""),
            );
          }
        }
      }
    }
  });

  return errors.concat(validateTotalFileSize(personalizations));
};

class ProductView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      quantity: 1,
      isExpress: false,
      personalizations: props.product.customizations.map((c) =>
        buildPersonalization(c),
      ),
    };
  }

  getPrice() {
    const { product } = this.props;
    const { isExpress, quantity, personalizations } = this.state;

    const personalizationsCost = personalizations
      .filter((p) => p.active === true)
      .reduce(
        (sum, p) => sum + (p.price !== null ? parseFloat(p.price) : 0),
        0,
      );
    const expressCost =
      product.product_type === "original" &&
      parseFloat(product.express_price) > 0 &&
      isExpress
        ? parseFloat(product.express_price)
        : 0;

    return (
      quantity *
      (parseFloat(product.base_price) + personalizationsCost + expressCost)
    );
  }

  validateAddToCart(e) {
    const { personalizations } = this.state;

    if (personalizations.length === 0) return; // Skip if no personalizations

    // Mandatory personalizations
    const mandatoryPersonalizations = personalizations.filter(
      (p) => p.mandatory === true && p.value === "",
    );

    if (mandatoryPersonalizations.length > 0) {
      e.preventDefault();
      alert(
        `Моля, попълнете следните задължителни персонализации:\n\n${mandatoryPersonalizations
          .map((p) => p.name)
          .join("\n")}`,
      );
      return;
    }

    // Free optional personalizations
    const freeNonMandatory = personalizations.filter(
      (p) => !p.mandatory && p.free,
    );
    const filledFreePersonalizations = personalizations.filter(
      (p) => !p.mandatory && p.free && p.value !== "",
    );

    if (
      filledFreePersonalizations.length === 0 &&
      freeNonMandatory.length > 0
    ) {
      e.preventDefault();
      alert(
        "Моля, попълнете поне една незадължителна безплатна персонализация!",
      );
      return;
    }

    // validate additional personalizations
    const additionaPersonalizations = personalizations.filter(
      (p) => parseFloat(p.price) > 0,
    );
    const activatedPersonalizations = additionaPersonalizations.filter(
      (p) => p.active === true,
    );
    const invalidAdditionalPersonalizations = activatedPersonalizations.filter(
      (p) => p.type !== "checkbox" && p.value === "",
    );

    if (invalidAdditionalPersonalizations.length > 0) {
      e.preventDefault();
      alert(
        `Моля, въведете текст за следните персонализации:\n\n${invalidAdditionalPersonalizations
          .map((p) => p.name)
          .join("\n")}`,
      );
      return;
    }

    // validate files
    const errors = validateFiles(personalizations);

    if (errors.length > 0) {
      e.preventDefault();
      alert(errors.join("\n\n"));
      return;
    }

    this.setState({ isLoading: true });
  }

  render() {
    const { product, cart_url, csrf } = this.props;
    const { isLoading, quantity, isExpress } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="shift-1">
            <div className="row">
              <div className="col-12">
                <hgroup>
                  <h1>{product.name}</h1>

                  <div className="meta-information">
                    <span>
                      <strong>
                        {product.product_type === "original" && quantity > 2
                          ? "Цена преди отстъпка"
                          : "Цена"}
                        :{" "}
                      </strong>
                      <Currency number={this.getPrice()} />
                    </span>
                    <span>
                      <strong>код на продукта:</strong> {product.sku}
                    </span>
                    {product.product_type === "original" ? (
                      <span>
                        <strong>време за изработка:</strong>{" "}
                        {product.production_time > 1
                          ? `${product.production_time} дни`
                          : "1 ден"}
                      </span>
                    ) : null}
                    <span
                      className={
                        product.availability === "available"
                          ? "text-success"
                          : "text-warning"
                      }
                    >
                      {product.availability === "available"
                        ? "в наличност"
                        : "следваща доставка: до седмица"}
                    </span>
                  </div>
                </hgroup>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="product-images-mobile">
                  <Slider
                    rebuildOnUpdate
                    pagination={{
                      el: ".swiper-pagination",
                      type: "bullets",
                      clickable: true,
                    }}
                    paginationClickable
                    slidesPerView={1}
                    spaceBetween={0}
                    freeMode
                    loop
                    speed={500}
                  >
                    {product.images.map((img, idx) => (
                      <Img key={idx} src={img} variant="square" />
                    ))}
                  </Slider>
                </div>

                <Gallery
                  images={product.images}
                  freeDelivery={product.free_delivery}
                />

                {product.video_url && (
                  <div>
                    <br />
                    <ProductVideo
                      variant="desktop"
                      videoUrl={product.video_url}
                    />
                  </div>
                )}
              </div>

              <div className="col-8">
                <div className="product-details">
                  <div
                    className="product-description-wrapper"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />

                  <DetailsNavigation product={product} />

                  <form
                    method="post"
                    encType="multipart/form-data"
                    action={cart_url}
                    onSubmit={(e) => this.validateAddToCart(e)}
                  >
                    {
                      <Personalizations
                        product={product}
                        onChange={(personalizations) =>
                          this.setState({ personalizations })
                        }
                      />
                    }

                    {product.product_type === "original" &&
                      parseFloat(product.express_price) > 0 && (
                        <label className="express">
                          <input type="hidden" name="is_express" value="0" />
                          <input
                            type="checkbox"
                            name="is_express"
                            value="1"
                            checked={isExpress}
                            onChange={(e) =>
                              this.setState({ isExpress: e.target.checked })
                            }
                          />{" "}
                          Желая експресна поръчка (
                          <span className="danger-text">
                            + <Currency number={product.express_price} />
                          </span>
                          )
                        </label>
                      )}

                    <div
                      className={`add-to-cart ${
                        product.product_type === "original" && quantity > 2
                          ? "discounted"
                          : ""
                      }`}
                    >
                      <SimpleLayout>
                        <SimpleCol>
                          <h5>Брой продукт с този дизайн</h5>
                          <Steper
                            value={quantity}
                            onChange={(quantity) => this.setState({ quantity })}
                          />
                        </SimpleCol>
                        <SimpleCol>
                          <h5>
                            {product.product_type === "original" && quantity > 2
                              ? "Цена преди отстъпка"
                              : "Цена"}
                          </h5>
                          <Currency number={this.getPrice()} />
                        </SimpleCol>
                      </SimpleLayout>

                      {product.product_type === "original" ? (
                        <p className="discount-note">
                          При над 2 броя продукт с еднакъв дизайн, предлагаме
                          отстъпки. Наш сътрудник ще се свърже с Вас за
                          уточнение, след преглед на материалите.
                        </p>
                      ) : (
                        <br />
                      )}
                    </div>

                    <input type="hidden" name="product_id" value={product.id} />
                    <input type="hidden" name="quantity" value={quantity} />
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={csrf}
                    />

                    <SimpleLayout>
                      <SimpleCol>
                        <CartButton
                          name="post_action"
                          value="stay"
                          label="ИЗПРАТИ ПОРЪЧКА"
                          note="и поръчай още нещо"
                        />
                      </SimpleCol>
                      <SimpleCol>
                        <CartButton
                          name="post_action"
                          value="finish"
                          label="ИЗПРАТИ ПОРЪЧКА"
                          note="и финализирай"
                        />
                      </SimpleCol>
                    </SimpleLayout>

                    {product.product_type === "original" && (
                      <p>
                        <br />
                        След получване на поръчката Ви, наш дизайнер ще изработи
                        визия за подаръка и ще Ви изпратим имейл за одобрение от
                        Ваша страна. Моля, проверявайте имейла, който използвате
                        за изпращане на поръчка към нас.
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>

            {isLoading && (
              <div className="popup">
                <div className="popup-content">
                  <div className="loading-warning">
                    <h3>Моля, изчакайте!</h3>
                    <p>Продуктът се добавя към количката.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProductView.defaultProps = {};

export default ProductView;
