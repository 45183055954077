import React from "react";

const RadioOption = ({ name, value, label, checked, onChange }) => (
  <label className="radio-option">
    <input
      type="radio"
      name={name}
      checked={checked}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
    {label}
  </label>
);

export default RadioOption;
