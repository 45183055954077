import React from "react";
import { Img } from "@raketa-cms/raketa-cms";

const formatPrice = (price) => new Number(price).toFixed(2).replace(".00", "");

const Badge = ({ product }) => {
  let label = "";
  if (product.is_new) label = "Нов";
  if (!product.is_new && product.most_ordered) label = "Най-поръчван";

  if (label === "") return null;

  return <h6 className="product-status">{label}</h6>;
};

const ProductItemView = ({ item, className }) => (
  <div className={className} key={item.product.id}>
    <a href={item.product.full_slug}>
      <article className="product-item">
        <Badge product={item.product} />

        <Img src={item.product.image} variant="square" />

        <div className="text">
          {item.product.free_delivery && <i className="free-dellivery-badge" />}

          <h5 className="title">{item.product.name}</h5>

          <div className="price-wrapper">
            <h3 className="price">
              {formatPrice(item.product.base_price)} лв.
            </h3>
            {parseFloat(item.product.old_price) > 0 ? (
              <h6 className="old-price">
                {formatPrice(item.product.old_price)} лв.
              </h6>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="product-overlay">
          <h5 className="product-title">{item.product.name}</h5>
          <p className="product-description">{item.product.mini_description}</p>
          <span className="see-more">Виж повече</span>
        </div>
      </article>
    </a>
  </div>
);

const ProductItem = ({ item, className }) =>
  item.product.active ? (
    <ProductItemView item={item} className={className} />
  ) : null;

export default ProductItem;
