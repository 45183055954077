import React from "react";
import Step from "../Step";
import CustomerStep from "./CustomerStep";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.guest.name,
      email: props.guest.email,
      phone: props.guest.phone,
      terms: props.guest.terms,
      newsletter: props.guest.newsletter,
      loginEmail: "",
      loginPassword: "",
      visibleSection: props.type,
      registrationName: props.registration.name,
      registrationEmail: props.registration.email,
      registrationPhone: props.registration.phone,
      registrationPassword: props.registration.password,
      registrationPasswordConfirmation:
        props.registration.password_confirmation,
      registrationTerms: props.registration.terms,
      registrationNewsletter: props.registration.newsletter,
      disableNavigation: false,
      remoteCustomerValidationPass: false,
      errors: {
        nameError: "",
        emailError: "",
        phoneError: "",
        termsError: "",
        registrationNameError: "",
        registrationEmailError: "",
        registrationPhoneError: "",
        registrationPasswordError: "",
        registrationPasswordConfirmationError: "",
        registrationTermsError: "",
      },
    };

    this.validateCustomer = this.validateCustomer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDecline = this.onDecline.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onDecline(currentVisibleSection) {
    if (currentVisibleSection === "login") {
      this.setState({
        visibleSection: "choice",
        loginEmail: "",
        loginPassword: "",
      });
    }

    if (currentVisibleSection === "register") {
      this.setState({
        visibleSection: "choice",
        registrationName: "",
        registrationEmail: "",
        registrationPhone: "",
        registrationPassword: "",
        registrationPasswordConfirmation: "",
        registrationTerms: false,
        registrationNewsletter: false,
      });
    }
  }

  onToggle(newVisibleSection) {
    if (newVisibleSection === "login" || newVisibleSection === "register") {
      this.setState({
        remoteCustomerValidationPass: false,
        visibleSection: newVisibleSection,
        name: "",
        email: "",
        phone: "",
        terms: false,
        newsletter: false,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { visibleSection } = this.state;

    this.setState({
      disableNavigation: true,
      errors: {
        registrationNameError: "",
        registrationEmailError: "",
        registrationPhoneError: "",
        registrationPasswordError: "",
        registrationPasswordConfirmationError: "",
        registrationTermsError: "",
        nameError: "",
        emailError: "",
        phoneError: "",
        termsError: "",
      },
    });

    this.validateCustomer(visibleSection, e.target);
  }

  validateCustomer(visibleSection, form) {
    if (visibleSection === "choice") {
      this.validateAnonymousCustomer(form);
    }

    if (visibleSection === "register") {
      this.validateRegistrationCustomer(form);
    }
  }

  validateAnonymousCustomer(form) {
    const { csrf } = this.props;
    const { name, email, phone, terms } = this.state;

    const data = {
      name,
      email,
      phone,
      terms_of_service: terms ? "1" : "0",
    };

    $.ajax({
      method: "post",
      url: "/store/customers/validate_anonymous",
      data: { anonymous: data, authenticity_token: csrf },
      success: () => {
        this.setState({
          disableNavigation: false,
          remoteCustomerValidationPass: true,
          errors: {
            nameError: "",
            emailError: "",
            phoneError: "",
            termsError: "",
          },
        });
        form.submit();
      },
      error: (data) => {
        const content = $.parseJSON(data.responseText);
        this.setState({
          disableNavigation: false,
          remoteCustomerValidationPass: false,
          errors: {
            nameError: content.errors.name,
            emailError: content.errors.email,
            phoneError: content.errors.phone,
            termsError: content.errors.terms_of_service,
          },
        });
      },
    });
  }

  validateRegistrationCustomer(form) {
    const { csrf } = this.props;
    const {
      registrationName,
      registrationEmail,
      registrationPhone,
      registrationPassword,
      registrationPasswordConfirmation,
      registrationTerms,
    } = this.state;

    const data = {
      name: registrationName,
      email: registrationEmail,
      phone: registrationPhone,
      password: registrationPassword,
      password_confirmation: registrationPasswordConfirmation,
      terms_of_service: registrationTerms ? "1" : "0",
    };

    $.ajax({
      method: "post",
      url: "/store/customers/validate_registration",
      data: { registration: data, authenticity_token: csrf },
      success: () => {
        this.setState({
          disableNavigation: false,
          remoteCustomerValidationPass: true,
          errors: {
            registrationNameError: "",
            registrationEmailError: "",
            registrationPhoneError: "",
            registrationPasswordError: "",
            registrationPasswordConfirmationError: "",
            registrationTermsError: "",
          },
        });
        form.submit();
      },
      error: (data) => {
        const content = $.parseJSON(data.responseText);
        this.setState({
          disableNavigation: false,
          remoteCustomerValidationPass: false,
          errors: {
            registrationNameError: content.errors.name,
            registrationEmailError: content.errors.email,
            registrationPhoneError: content.errors.phone,
            registrationPasswordError: content.errors.password,
            registrationPasswordConfirmationError:
              content.errors.password_confirmation,
            registrationTermsError: content.errors.terms_of_service,
          },
        });
      },
    });
  }

  render() {
    const { csrf, loginUrl, backUrl, customer, submitUrl } = this.props;

    const {
      visibleSection,
      name,
      email,
      phone,
      terms,
      newsletter,
      loginEmail,
      loginPassword,
      registrationName,
      registrationEmail,
      registrationPhone,
      registrationPassword,
      registrationPasswordConfirmation,
      registrationNewsletter,
      registrationTerms,
      errors,
    } = this.state;

    return (
      <div className="checkout">
        <Step
          title="Информация за клиента"
          step="customer"
          active
          showCustomer={!customer}
        >
          {!customer && (
            <CustomerStep
              csrf={csrf}
              name={name}
              email={email}
              phone={phone}
              terms={terms}
              newsletter={newsletter}
              loginEmail={loginEmail}
              loginPassword={loginPassword}
              registrationName={registrationName}
              registrationEmail={registrationEmail}
              registrationPhone={registrationPhone}
              registrationPassword={registrationPassword}
              registrationPasswordConfirmation={
                registrationPasswordConfirmation
              }
              registrationTerms={registrationTerms}
              registrationNewsletter={registrationNewsletter}
              loginUrl={loginUrl}
              backUrl={backUrl}
              visibleSection={visibleSection}
              onToggle={(visibleSection) => this.onToggle(visibleSection)}
              onChange={(field, value) =>
                this.setState({
                  [field]: value,
                  remoteCustomerValidationPass: false,
                })
              }
              errors={errors}
              submitUrl={submitUrl}
              onSubmit={this.onSubmit}
              onDecline={this.onDecline}
            />
          )}
        </Step>
      </div>
    );
  }
}

export default Checkout;
