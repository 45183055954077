import React from "react";
import Captcha from "./Captcha";

class FormCaptcha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptchaResponse: "",
      isCaptchaValid: false,
    };
  }

  componentDidMount() {
    this.disableSubmits();
  }

  getSubmits() {
    const { form_id } = this.props;
    return [
      ...document
        .getElementById(form_id)
        .querySelectorAll("input[type=submit]"),
    ];
  }

  enableSubmits() {
    this.getSubmits().forEach((el) => el.removeAttribute("disabled"));
  }

  disableSubmits() {
    this.getSubmits().forEach((el) => el.setAttribute("disabled", true));
  }

  handleButtonChange(isButtonEnabled) {
    isButtonEnabled ? this.enableSubmits() : this.disableSubmits();
  }

  handleVerifyingCaptcha(status, captchaResponse) {
    if (status) {
      this.setState({
        isCaptchaValid: true,
        recaptchaResponse: captchaResponse,
      });
    }
  }

  render() {
    const { recaptcha_key, form_name } = this.props;
    const { recaptchaResponse, isCaptchaValid } = this.state;

    if (isCaptchaValid) this.handleButtonChange(isCaptchaValid);

    return (
      <div>
        <Captcha
          recaptchaKey={recaptcha_key}
          verifyCallback={(status, captchaResponse) =>
            this.handleVerifyingCaptcha(status, captchaResponse)
          }
        />

        <input
          type="hidden"
          name={`${form_name}[recaptcha_response]`}
          value={recaptchaResponse}
        />
      </div>
    );
  }
}

export default FormCaptcha;
