import React from "react";
import PropTypes from "prop-types";
import { Img } from "@raketa-cms/raketa-cms";

const formatPrice = (price) => new Number(price).toFixed(2).replace(".00", "");

const Badge = ({ product }) => {
  let label = "";
  if (product.is_new) label = "Нов";
  if (!product.is_new && product.most_ordered) label = "Най-поръчван";

  if (label === "") return null;

  return <h6 className="product-status">{label}</h6>;
};

const ProductFeedSlim = ({ list }) => (
  <div className="container product-feed-slim">
    <div className="row">
      {list.map((item, idx) => (
        <div className="col-2" key={idx}>
          <a href={item.product.full_slug}>
            <article className="product-item">
              <Badge product={item.product} />

              <Img src={item.product.image} variant="thumb" />

              <div className="product-overlay">
                <h5 className="product-title">{item.product.name}</h5>
                <h6 className="product-price">
                  {formatPrice(item.product.base_price)} лв.
                </h6>
              </div>
            </article>
          </a>
        </div>
      ))}
    </div>
  </div>
);

ProductFeedSlim.defaultProps = {
  list: [],
};

ProductFeedSlim.propTypes = {
  list: PropTypes.array,
};

export default ProductFeedSlim;
