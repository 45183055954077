import React from "react";
import PropTypes from "prop-types";
import { Container, List, TextInput, SelectMenu } from "@raketa-cms/raketa-cms";

const CtaButtonWidget = ({ list, variant, containerSettings }) => (
  <Container className="cta-buttons" settings={containerSettings}>
    <div className="container">
      <div className="row">
        {list.map((item, idx) => (
          <div className="col-4" key={idx}>
            <a
              href={item.link}
              className={variant === "accent" ? "btn-primary" : "btn-secondary"}
            >
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </div>
  </Container>
);

CtaButtonWidget.title = "CTA Button";
CtaButtonWidget.category = "General";

CtaButtonWidget.defaultProps = {
  list: [],
};

CtaButtonWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
};

CtaButtonWidget.defaults = {
  list: [
    { id: 1, label: "Button 1", link: "#" },
    { id: 2, label: "Button 2", link: "#" },
    { id: 3, label: "Button 3", link: "#" },
  ],
  containerSettings: {},
  variant: "accent",
};

const ButtonFields = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <TextInput
      label="Label"
      onChange={(value) => onChangeItem("label", value)}
      value={settings.label}
    />
  </div>
);

ButtonFields.defaultProps = {
  settings: {},
};

ButtonFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

CtaButtonWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["accent", "Accent"],
        ["normal", "Normal"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ButtonFields settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      titleField="label"
      template={[{ label: "Button 1", link: "#" }]}
      primaryField="label"
    />
  </div>
);

export default CtaButtonWidget;
