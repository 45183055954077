import React from "react";
import { ImageInput } from "@raketa-cms/raketa-image-picker";
import MediaManager from "./MediaManager";

// eslint-disable-next-line react/prop-types
export default ({ name, image }) => {
  // eslint-disable-next-line no-undef;
  const mediaManager = new MediaManager("/");

  return <ImageInput name={name} image={image} mediaManager={mediaManager} />;
};
