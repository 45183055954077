import React from "react";

const Checkbox = ({
  name,
  value,
  label,
  checked,
  className,
  error,
  children,
  onChange,
  onBlur,
  required,
}) => (
  <div className={`form-group ${error && "has-error"}`}>
    <label className={`checkbox ${className} ${error && "form-error"}`}>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        onBlur={onBlur}
      />
      {required && <abbr title="задължително">*</abbr>}
      {children || label}
    </label>
  </div>
);

Checkbox.defaultProps = {
  className: "",
};

export default Checkbox;
