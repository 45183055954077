import React from "react";
import PropTypes from "prop-types";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const StoreFeedWidget = ({
  leftColImage,
  rightColImage,
  leftColDescription,
  rightColDescription,
  leftColTitle,
  rightColTitle,
  leftColLink,
  rightColLink,
  containerSettings,
}) => (
  <Container className="store-feed" settings={containerSettings}>
    <div className="container">
      <div className="row">
        <div className="col-6">
          <div className="store-feed-col">
            <Img src={leftColImage} variant="square" />

            <div className="text">
              <h4>{leftColTitle}</h4>

              <div className="description">
                <div dangerouslySetInnerHTML={{ __html: leftColDescription }} />
              </div>

              <a href={leftColLink} className="address-location" />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="store-feed-col">
            <Img src={rightColImage} variant="square" />

            <div className="text">
              <h4>{rightColTitle}</h4>

              <div className="description">
                <div
                  dangerouslySetInnerHTML={{ __html: rightColDescription }}
                />
              </div>

              <a href={rightColLink} className="address-location" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

StoreFeedWidget.title = "Store Feed";
StoreFeedWidget.category = "Media";

StoreFeedWidget.propTypes = {
  leftColImage: PropTypes.any.isRequired,
  leftColDescription: PropTypes.string.isRequired,
  leftColTitle: PropTypes.string.isRequired,
  leftColLink: PropTypes.string.isRequired,
  rightColImage: PropTypes.any.isRequired,
  rightColDescription: PropTypes.string.isRequired,
  rightColTitle: PropTypes.string.isRequired,
  rightColLink: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

StoreFeedWidget.defaults = {
  leftColImage: imagePlaceholder("460x460"),
  leftColDescription:
    "София, ул. Цар Асен 11, център до Съдебната палата: Понеделник - петък: 10:00 - 19:00 часа Събота: 10:00 - 14:00 часа Неделя: почивен ден +359 886 44 94 93 +359 2 444 0322",
  leftColTitle: "Магазин София",
  leftColLink: "#",
  rightColImage: imagePlaceholder("460x460"),
  rightColDescription:
    "София, ул. Цар Асен 11, център до Съдебната палата: Понеделник - петък: 10:00 - 19:00 часа Събота: 10:00 - 14:00 часа Неделя: почивен ден +359 886 44 94 93 +359 2 444 0322",
  rightColTitle: "Магазин Хасково",
  rightColLink: "#",
  containerSettings: {},
};

StoreFeedWidget.adminFields = {
  leftColImage: { type: "custom", component: ImagePicker },
  leftColDescription: { type: "custom", component: RichText },
  leftColTitle: { type: "text" },
  leftColLink: { type: "text" },
  rightColImage: { type: "custom", component: ImagePicker },
  rightColDescription: { type: "custom", component: RichText },
  rightColTitle: { type: "text" },
  rightColLink: { type: "text" },
};

export default StoreFeedWidget;
