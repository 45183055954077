import React from "react";

const addToCart = (product, quantity) => {
  $.post(
    "/store/cart",
    { product: product.id, quantity },
    (resp) => {
      window.location.reload();
    },
    "json",
  );
};

const Product = ({ product, className, children }) => (
  <button
    type="button"
    name="button"
    className={className}
    onClick={() => addToCart(product, 1)}
  >
    {children}
  </button>
);

export default Product;
