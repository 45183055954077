import React from "react";
import PropTypes from "prop-types";
import { Img, Container, List, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";

import ConditionalElement from "../ConditionalElement";

const CategoryFeedWidget = ({ list, containerSettings }) => (
  <Container className="category-feed" settings={containerSettings}>
    <div className="container">
      <div className="row">
        {list.map((item, idx) => (
          <div className="col-3" key={idx}>
            <article className="category-item">
              <ConditionalElement
                tagName="a"
                condition={item.mainLink !== ""}
                attributes={{ href: item.mainLink }}
                childrenElements={
                  <div>
                    <div className="title-wrapper">
                      <h2 className="category-title">{item.title}</h2>
                    </div>

                    <Img src={item.image} variant="square" />
                  </div>
                }
              />

              <div className="category-overlay">
                {item.link_1 !== "" ? (
                  <a href={item.link_1}>{item.link_label_1}</a>
                ) : (
                  ""
                )}
                {item.link_2 !== "" ? (
                  <a href={item.link_2}>{item.link_label_2}</a>
                ) : (
                  ""
                )}
                {item.link_3 !== "" ? (
                  <a href={item.link_3}>{item.link_label_3}</a>
                ) : (
                  ""
                )}
                {item.link_4 !== "" ? (
                  <a href={item.link_4}>{item.link_label_4}</a>
                ) : (
                  ""
                )}
                {item.link_5 !== "" ? (
                  <a href={item.link_5}>{item.link_label_5}</a>
                ) : (
                  ""
                )}
                {item.link_6 !== "" ? (
                  <a href={item.link_6}>{item.link_label_6}</a>
                ) : (
                  ""
                )}
                {item.link_7 !== "" ? (
                  <a href={item.link_7}>{item.link_label_7}</a>
                ) : (
                  ""
                )}
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>
  </Container>
);

CategoryFeedWidget.title = "Category Feed";
CategoryFeedWidget.category = "General";

CategoryFeedWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CategoryFeedWidget.defaults = {
  list: [
    {
      id: 1,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      mainLink: "",
      link_1: "",
      link_label_1: "",
      link_2: "",
      link_label_2: "",
      link_3: "",
      link_label_3: "",
      link_4: "",
      link_label_4: "",
      link_5: "",
      link_label_5: "",
      link_6: "",
      link_label_6: "",
      link_7: "",
      link_label_7: "",
    },
    {
      id: 2,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      mainLink: "",
      link_1: "",
      link_label_1: "",
      link_2: "",
      link_label_2: "",
      link_3: "",
      link_label_3: "",
      link_4: "",
      link_label_4: "",
      link_5: "",
      link_label_5: "",
      link_6: "",
      link_label_6: "",
      link_7: "",
      link_label_7: "",
    },
    {
      id: 3,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      mainLink: "",
      link_1: "",
      link_label_1: "",
      link_2: "",
      link_label_2: "",
      link_3: "",
      link_label_3: "",
      link_4: "",
      link_label_4: "",
      link_5: "",
      link_label_5: "",
      link_6: "",
      link_label_6: "",
      link_7: "",
      link_label_7: "",
    },
    {
      id: 4,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      mainLink: "",
      link_1: "",
      link_label_1: "",
      link_2: "",
      link_label_2: "",
      link_3: "",
      link_label_3: "",
      link_4: "",
      link_label_4: "",
      link_5: "",
      link_label_5: "",
      link_6: "",
      link_label_6: "",
      link_7: "",
      link_label_7: "",
    },
  ],
  containerSettings: {},
};

const CategoryFields = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Main Link"
      onChange={(value) => onChangeItem("mainLink", value)}
      value={settings.mainLink}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Link 1"
      onChange={(value) => onChangeItem("link_1", value)}
      value={settings.link_1}
    />

    <TextInput
      label="Link Label 1"
      onChange={(value) => onChangeItem("link_label_1", value)}
      value={settings.link_label_1}
    />

    <TextInput
      label="Link 2"
      onChange={(value) => onChangeItem("link_2", value)}
      value={settings.link_2}
    />

    <TextInput
      label="Link Label 2"
      onChange={(value) => onChangeItem("link_label_2", value)}
      value={settings.link_label_2}
    />

    <TextInput
      label="Link 3"
      onChange={(value) => onChangeItem("link_3", value)}
      value={settings.link_3}
    />

    <TextInput
      label="Link Label 3"
      onChange={(value) => onChangeItem("link_label_3", value)}
      value={settings.link_label_3}
    />

    <TextInput
      label="Link 4"
      onChange={(value) => onChangeItem("link_4", value)}
      value={settings.link_4}
    />

    <TextInput
      label="Link Label 4"
      onChange={(value) => onChangeItem("link_label_4", value)}
      value={settings.link_label_4}
    />

    <TextInput
      label="Link 5"
      onChange={(value) => onChangeItem("link_5", value)}
      value={settings.link_5}
    />

    <TextInput
      label="Link Label 5"
      onChange={(value) => onChangeItem("link_label_5", value)}
      value={settings.link_label_5}
    />

    <TextInput
      label="Link 6"
      onChange={(value) => onChangeItem("link_6", value)}
      value={settings.link_6}
    />

    <TextInput
      label="Link Label 6"
      onChange={(value) => onChangeItem("link_label_6", value)}
      value={settings.link_label_6}
    />

    <TextInput
      label="Link 7"
      onChange={(value) => onChangeItem("link_7", value)}
      value={settings.link_7}
    />

    <TextInput
      label="Link Label 7"
      onChange={(value) => onChangeItem("link_label_7", value)}
      value={settings.link_label_7}
    />
  </div>
);

CategoryFields.defaultProps = {
  settings: {},
};

CategoryFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

CategoryFeedWidget.adminFields = (items, onChange, settings) => (
  <List
    listItem={(settings, onChangeItem) => (
      <CategoryFields settings={settings} onChangeItem={onChangeItem} />
    )}
    onChangeList={onChange}
    items={items}
    template={[
      {
        image: imagePlaceholder("430x430"),
        title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
        mainLink: "",
        link_1: "",
        link_label_1: "",
        link_2: "",
        link_label_2: "",
        link_3: "",
        link_label_3: "",
        link_4: "",
        link_label_4: "",
        link_5: "",
        link_label_5: "",
        link_6: "",
        link_label_6: "",
        link_7: "",
        link_label_7: "",
      },
    ]}
    primaryField="title"
  />
);

export default CategoryFeedWidget;
