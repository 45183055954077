import React from "react";
import CartStep from "./CartStep";
import Step from "../Step";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: props.cart || { total: 0, cart_items: [] },
      note: props.note || "",
      hasInvoice: props.hasInvoice,
      invoiceInfo: props.invoiceInfo || "",
      discountCode: props.discountCode || "",
      voucher: false,
      freeDelivery: props.cart
        ? props.cart.cart_items.some((ci) => ci.product_free_delivery === true)
        : false,
      voucherMessage: "",
      disableNavigation: false,
    };

    this.handleDiscountCode = this.handleDiscountCode.bind(this);
    this.handleUpdateProduct = this.handleUpdateProduct.bind(this);
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
    this.getVoucherDiscount = this.getVoucherDiscount.bind(this);
  }

  handleUpdateProduct(item, quantity) {
    if (parseInt(quantity, 10) < 1) return;

    $.ajax({
      method: "patch",
      url: `/store/cart/${item.id}`,
      data: { quantity },
      success: (cart) => {
        this.setState({ cart });
      },
    });
  }

  handleDeleteProduct(item) {
    if (
      !confirm(
        "Сигурни ли сте, че искате да премахнете този продукт от количката? ",
      )
    )
      return;

    $.ajax({
      method: "delete",
      url: `/store/cart/${item.id}`,
      success: (cart) => {
        this.setState({ cart });
      },
    });
  }

  handleDiscountCode() {
    const { discountCode } = this.state;

    $.get(
      `/store/vouchers/${discountCode}`,
      (voucher) => {
        if (!voucher || (voucher && !voucher.active)) {
          this.setState({
            voucher: false,
            freeDelivery: false,
            voucherMessage: voucher.voucher_error,
          });
          return;
        }

        this.setState({
          voucher,
          freeDelivery: voucher.free_delivery,
          voucherMessage: voucher.voucher_info,
        });
      },
      "json",
    );
  }

  moveToNext() {
    window.scroll(0, 0);

    const { customer } = this.props;
    const { currentStep } = this.state;
    const nextStep = {
      cart: customer ? "delivery" : "customer",
      customer: "delivery",
      delivery: "summary",
      summary: null,
    }[currentStep];

    this.setState({ currentStep: nextStep }, () => {
      const email = this.state.customer
        ? this.state.customer.email
        : this.state.email;
    });
  }

  hasDiscount(cart) {
    const items = cart.cart_items;
    return (
      items
        .filter((i) => i.personalizations.length > 0)
        .filter((i) => i.quantity > 2).length > 0
    );
  }

  getVoucherDiscount(voucher, totalWithDelivery) {
    if (voucher.discount_type === "fixed") return voucher.discount;
    if (voucher.discount_type === "percent")
      return (
        (parseFloat(totalWithDelivery) / 100) * parseInt(voucher.discount, 10)
      );

    return 0;
  }

  getCourier() {
    const { couriers } = this.props;
    const { courierId } = this.state;

    return couriers.find((c) => parseInt(c.id, 10) === parseInt(courierId, 10));
  }

  render() {
    const { customer, personalizationNames, csrf, submitUrl } = this.props;

    const {
      cart,
      hasInvoice,
      invoiceInfo,
      discountCode,
      voucherMessage,
      note,
    } = this.state;

    const hasDiscount = this.hasDiscount(cart);

    return (
      <div className="checkout">
        <Step
          title="Вашата поръчка"
          step="cart"
          active
          showCustomer={!customer}
        >
          <CartStep
            personalizationNames={personalizationNames}
            cart={cart}
            hasDiscount={hasDiscount}
            discountCode={discountCode}
            voucherMessage={voucherMessage}
            hasInvoice={hasInvoice}
            invoiceInfo={invoiceInfo}
            onCartUpdateProduct={(item, quantity) =>
              this.handleUpdateProduct(item, quantity)
            }
            onCartDeleteProduct={(item) => this.handleDeleteProduct(item)}
            onChange={(field, value) => this.setState({ [field]: value })}
            onDiscountCodeApply={this.handleDiscountCode}
            customer={customer}
            csrf={csrf}
            submitUrl={submitUrl}
            note={note}
          />
        </Step>
      </div>
    );
  }
}

Checkout.defaultProps = {};

export default Checkout;
