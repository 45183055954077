import React from "react";
import Autocomplete from "./Autocomplete";

import { TextInput, Dialog, RaketaUIProvider } from "@raketa-cms/raketa-cms";
import DialogWrapper from "../DialogWrapper";
import randomString from "../randomString";

class NavItem extends React.Component {
  constructor(props) {
    super(props);

    const { item } = this.props;

    this.state = {
      isOpen: false,
      item: {
        id: item.id,
        label: item.label,
        url: item.url,
      },
    };
  }

  handleUpdate(field, value) {
    this.setState({
      item: Object.assign({}, this.state.item, { [field]: value }),
    });
  }

  handleChangeProduct(newProduct) {
    const { item } = this.state;

    this.setState({
      item: Object.assign({}, item, {
        label: newProduct.name,
        url: `/${newProduct.slug}`,
      }),
    });
  }

  render() {
    const { isOpen, item } = this.state;
    const { children, onChange, onDelete, productsUrl } = this.props;

    return (
      <div className="nav-item">
        {children}
        <button
          className="btn-edit"
          onClick={() => this.setState({ isOpen: true })}
        >
          <i className="fa fa-pencil" />
        </button>
        <button className="btn-rm" onClick={() => onDelete(item.id)}>
          &times;
        </button>

        <DialogWrapper open={isOpen}>
          <Dialog
            open={isOpen}
            title="Редакция на меню"
            primaryLabel="Запази"
            secondaryLabel="Откажи"
            width="600px"
            onPrimary={() =>
              this.setState({ isOpen: false }, () => onChange(item))
            }
            onSecondary={() =>
              this.setState({ item: this.props.item, isOpen: false })
            }
            onClose={() => this.setState({ isOpen: false })}
          >
            <div style={{ marginBottom: "1em" }}>
              <label className="control-label">Продукт</label>

              <Autocomplete
                searchUrl={productsUrl}
                searchTermKey="product_info"
                itemToString={(product) => `${product.name} - ${product.slug}`}
                onSelected={(newProduct) =>
                  this.handleChangeProduct(newProduct)
                }
              />
            </div>

            <TextInput
              label="Label"
              value={item.label}
              onChange={(label) => this.handleUpdate("label", label)}
            />

            <TextInput
              label="URL"
              value={item.url}
              onChange={(url) => this.handleUpdate("url", url)}
            />
          </Dialog>
        </DialogWrapper>
      </div>
    );
  }
}

const TopNavEditor = ({
  tabIdx,
  items,
  productsUrl,
  onChangeTab,
  onAdd,
  onChange,
  onDelete,
}) => (
  <div className="top-nav">
    {items.map((item, idx) => (
      <div
        key={item.id}
        className={`top-nav-item ${tabIdx === idx && "active"}`}
      >
        <NavItem
          item={item}
          productsUrl={productsUrl}
          onChange={onChange}
          onDelete={onDelete}
        >
          <button className="tab" onClick={() => onChangeTab(idx)}>
            {item.label}
          </button>
        </NavItem>
      </div>
    ))}

    <button className="add-top-nav-item" onClick={onAdd}>
      +
    </button>
  </div>
);

const MenuTabEditor = ({
  item,
  productsUrl,
  isOpen,
  onAddRow,
  onDeleteRow,
  onAddColumn,
  onDeleteColumn,
  onUpdateColumn,
  onAddColumnLink,
  onDeleteColumnLink,
  onUpdateColumnLink,
}) => (
  <div
    className="menu-tab-editor"
    style={{ display: isOpen ? "block" : "none" }}
  >
    {item.rows.map((row, idx) => (
      <MenuTabRow
        key={idx}
        row={row}
        idx={idx}
        productsUrl={productsUrl}
        onDeleteRow={onDeleteRow}
        onAddColumn={() => onAddColumn(idx)}
        onDeleteColumn={(colIdx) => onDeleteColumn(idx, colIdx)}
        onUpdateColumn={(colIdx, field, value) =>
          onUpdateColumn(idx, colIdx, field, value)
        }
        onAddColumnLink={(colIdx) => onAddColumnLink(idx, colIdx)}
        onDeleteColumnLink={(colIdx, linkIdx) =>
          onDeleteColumnLink(idx, colIdx, linkIdx)
        }
        onUpdateColumnLink={(colIdx, linkIdx, item) =>
          onUpdateColumnLink(idx, colIdx, linkIdx, item)
        }
      />
    ))}

    <button className="btn-secondary" onClick={onAddRow}>
      Add Row
    </button>
  </div>
);

const MenuTabRow = ({
  idx,
  row,
  productsUrl,
  onDeleteRow,
  onAddColumn,
  onDeleteColumn,
  onUpdateColumn,
  onAddColumnLink,
  onDeleteColumnLink,
  onUpdateColumnLink,
}) => (
  <div className="menu-tab-row">
    <div className="menu-tab-columns">
      {row.map((column, idx) => (
        <MenuTabColumn
          key={idx}
          column={column}
          idx={idx}
          onDeleteColumn={onDeleteColumn}
          onUpdateColumn={onUpdateColumn}
          onAddColumnLink={onAddColumnLink}
          onDeleteColumnLink={onDeleteColumnLink}
          onUpdateColumnLink={onUpdateColumnLink}
          productsUrl={productsUrl}
        />
      ))}

      <button className="add-column btn-secondary" onClick={onAddColumn}>
        +
      </button>
    </div>

    <div className="row-toolbar">
      <button className="btn-danger" onClick={() => onDeleteRow(idx)}>
        Remove row
      </button>
    </div>
  </div>
);

const MenuTabColumn = ({
  idx,
  column,
  productsUrl,
  onDeleteColumn,
  onUpdateColumn,
  onAddColumnLink,
  onDeleteColumnLink,
  onUpdateColumnLink,
}) => (
  <div className="menu-tab-column">
    <TextInput
      label="Column Label"
      value={column.label}
      onChange={(label) => onUpdateColumn(idx, "label", label)}
    />
    <TextInput
      label="Column URL"
      value={column.url}
      onChange={(url) => onUpdateColumn(idx, "url", url)}
    />

    {column.items.map((item, linkIdx) => (
      <div className="col-link" key={item.id}>
        <NavItem
          productsUrl={productsUrl}
          item={item}
          onChange={(item) => onUpdateColumnLink(idx, linkIdx, item)}
          onDelete={() => onDeleteColumnLink(idx, linkIdx)}
        >
          {item.label}
        </NavItem>
      </div>
    ))}
    <button className="add-column-link" onClick={() => onAddColumnLink(idx)}>
      Add link
    </button>

    <div>
      <button className="btn-danger" onClick={() => onDeleteColumn(idx)}>
        Remove column
      </button>
    </div>
  </div>
);

export default class MenuEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIdx: 0,
      menu: props.menu,
    };
  }

  handleAddTop() {
    const menu = [
      ...this.state.menu,
      { id: randomString(6), label: "New item", url: "#", rows: [] },
    ];

    this.setState({ menu });
  }

  handleDeleteTop(id) {
    if (!confirm("Are you sure? ")) return;

    const idx = this.state.menu.findIndex((i) => i.id === id);

    const menu = [
      ...this.state.menu.slice(0, idx),
      ...this.state.menu.slice(idx + 1),
    ];

    this.setState({ menu, tabIdx: 0 });
  }

  handleChangeTop(item) {
    const idx = this.state.menu.findIndex((i) => i.id === item.id);

    const menu = [
      ...this.state.menu.slice(0, idx),
      Object.assign({}, this.state.menu[idx], item),
      ...this.state.menu.slice(idx + 1),
    ];

    this.setState({ menu });
  }

  handleAddRow(id) {
    const idx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[idx];
    const newRows = [...item.rows, []];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, idx),
      newItem,
      ...this.state.menu.slice(idx + 1),
    ];

    this.setState({ menu });
  }

  handleDeleteRow(id, idx) {
    if (!confirm("Are you sure? ")) return;

    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const newRows = [...item.rows.slice(0, idx), ...item.rows.slice(idx + 1)];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  onAddColumn(id, rowIdx) {
    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];

    if (columns.length >= 4) {
      alert("Cannot add more than 4 columns per row. ");
      return;
    }

    const newRow = [
      ...columns,
      { id: randomString(6), label: "Column", items: [] },
    ];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  onDeleteColumn(id, rowIdx, colIdx) {
    if (!confirm("Are you sure? ")) return;

    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];

    const newRow = [...columns.slice(0, colIdx), ...columns.slice(colIdx + 1)];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  handleUpdateColumn(id, rowIdx, colIdx, field, value) {
    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];

    const newRow = [
      ...columns.slice(0, colIdx),
      Object.assign({}, columns[colIdx], { [field]: value }),
      ...columns.slice(colIdx + 1),
    ];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  handleAddColumnLink(id, rowIdx, colIdx) {
    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];
    const column = columns[colIdx];

    const newItems = [
      ...column.items,
      { id: randomString(6), label: "New item", url: "#", rows: [] },
    ];

    const newRow = [
      ...columns.slice(0, colIdx),
      Object.assign({}, columns[colIdx], { items: newItems }),
      ...columns.slice(colIdx + 1),
    ];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  handleDeleteColumnLink(id, rowIdx, colIdx, linkIdx) {
    if (!confirm("Are you sure? ")) return;

    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];
    const column = columns[colIdx];

    const newItems = [
      ...column.items.slice(0, linkIdx),
      ...column.items.slice(linkIdx + 1),
    ];

    const newRow = [
      ...columns.slice(0, colIdx),
      Object.assign({}, columns[colIdx], { items: newItems }),
      ...columns.slice(colIdx + 1),
    ];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  handleUpdateColumnLink(id, rowIdx, colIdx, linkIdx, linkItem) {
    const itemIdx = this.state.menu.findIndex((i) => i.id === id);
    const item = this.state.menu[itemIdx];
    const rows = item.rows;
    const columns = rows[rowIdx];
    const column = columns[colIdx];

    const newItems = [
      ...column.items.slice(0, linkIdx),
      Object.assign({}, column.items[linkIdx], linkItem),
      ...column.items.slice(linkIdx + 1),
    ];

    const newRow = [
      ...columns.slice(0, colIdx),
      Object.assign({}, columns[colIdx], { items: newItems }),
      ...columns.slice(colIdx + 1),
    ];

    const newRows = [
      ...rows.slice(0, rowIdx),
      newRow,
      ...rows.slice(rowIdx + 1),
    ];

    const newItem = Object.assign({}, item, { rows: newRows });

    const menu = [
      ...this.state.menu.slice(0, itemIdx),
      newItem,
      ...this.state.menu.slice(itemIdx + 1),
    ];

    this.setState({ menu });
  }

  handleSave() {
    const { menu } = this.state;
    const { update_url } = this.props;

    $.ajax({
      method: "PATCH",
      contentType: "application/json; charset=utf-8",
      url: update_url,
      data: JSON.stringify({ menu }),
      dataType: "json",
      success: (resp) => {
        console.log(resp);
      },
    });
  }

  render() {
    const { products_url } = this.props;
    const { tabIdx, menu } = this.state;

    return (
      <RaketaUIProvider>
        <div>
          <div className="menu-editor">
            <TopNavEditor
              tabIdx={tabIdx}
              items={menu}
              productsUrl={products_url}
              onChangeTab={(tabIdx) => this.setState({ tabIdx })}
              onChange={(item) => this.handleChangeTop(item)}
              onAdd={() => this.handleAddTop()}
              onDelete={(id) => this.handleDeleteTop(id)}
            />

            {menu.map((item, idx) => (
              <MenuTabEditor
                key={item.id}
                item={item}
                isOpen={tabIdx === idx}
                productsUrl={products_url}
                onAddRow={() => this.handleAddRow(item.id)}
                onDeleteRow={(itemIdx) =>
                  this.handleDeleteRow(item.id, itemIdx)
                }
                onAddColumn={(rowIdx) => this.onAddColumn(item.id, rowIdx)}
                onDeleteColumn={(rowIdx, colIdx) =>
                  this.onDeleteColumn(item.id, rowIdx, colIdx)
                }
                onUpdateColumn={(rowIdx, colIdx, field, value) =>
                  this.handleUpdateColumn(item.id, rowIdx, colIdx, field, value)
                }
                onAddColumnLink={(rowIdx, colIdx) =>
                  this.handleAddColumnLink(item.id, rowIdx, colIdx)
                }
                onDeleteColumnLink={(rowIdx, colIdx, linkIdx) =>
                  this.handleDeleteColumnLink(item.id, rowIdx, colIdx, linkIdx)
                }
                onUpdateColumnLink={(rowIdx, colIdx, linkIdx, linkItem) =>
                  this.handleUpdateColumnLink(
                    item.id,
                    rowIdx,
                    colIdx,
                    linkIdx,
                    linkItem,
                  )
                }
              />
            ))}
          </div>

          <button className="btn-primary" onClick={() => this.handleSave()}>
            Save
          </button>
        </div>
      </RaketaUIProvider>
    );
  }
}
