import React from "react";

const EmptyState = ({ categories }) => (
  <div>
    <div className="section-title-wrapper">
      <div className="container">
        <div className="container-inner">
          <h3 className="section-title">Вашата количка е все още празна</h3>
        </div>
      </div>
    </div>

    <div className=" spacing-bottom">
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <div className="row">
              <div className="text-box centered col-12">
                <div>
                  За да продължите, добавете продукти към количката си. По-долу
                  можете да намерите интересни предложения.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="category-feed spacing-bottom">
      <div className="container">
        <div className="row">
          {categories.map((category, idx) => (
            <div className="col-3">
              <a href={category.path} className="category-item">
                <div className="title-wrapper">
                  <h2 className="category-title">{category.title}</h2>
                </div>
                <img
                  src={category.image}
                  alt={category.image}
                  title={category.title}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default EmptyState;
