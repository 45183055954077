import React from "react";
import PropTypes from "prop-types";
import ProductItem from "./ProductItem";

const ProductFeedQuarters = ({ list }) => (
  <div className="container">
    <div className="row">
      <div className="shift-2">
        <div className="row">
          {list.map((item) => (
            <ProductItem className="col-3" item={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

ProductFeedQuarters.defaultProps = {
  list: [],
};

ProductFeedQuarters.propTypes = {
  list: PropTypes.array,
};

export default ProductFeedQuarters;
