import React from "react";

class Cart extends React.Component {
  renderItems() {
    const { cart } = this.props;
    const items = cart.cart_items;

    if (items.length === 0)
      return (
        <tr>
          <td colSpan="3">No items yet.</td>
        </tr>
      );

    const { onUpdate, onDelete } = this.props;

    return items.map((item) => (
      <tr key={item.id}>
        <td>{item.product_name}</td>
        <td>
          <input
            type="number"
            value={item.quantity}
            onChange={(e) => onUpdate(item, e.target.value)}
          />
        </td>
        <td>
          <button type="button" onClick={() => onDelete(item)}>
            &times;
          </button>
        </td>
      </tr>
    ));
  }

  render() {
    const { cart } = this.props;

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{this.renderItems()}</tbody>
          {cart.total > 0 && (
            <tfoot>
              <tr>
                <th colSpan="2">Total</th>
                <td>{cart.total}</td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

export default Cart;
