import React from "react";
import buildPersonalization from "./buildPersonalization";
import PersonalizationEditor from "./PersonalizationEditor";

const validFile = (file) => {
  const fileName = file[0].toLowerCase();
  const regex = new RegExp("(.*?).(bmp|png|jpeg|jpg|tiff)$");

  return regex.test(fileName);
};

const boolCompare = (a, b) => {
  if (a === true && b === false) {
    return -1;
  }

  if (a === false && b === true) {
    return 1;
  }

  return 0;
};

class Personalizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personalizations: props.product.customizations.map((c) =>
        buildPersonalization(c),
      ),
    };
  }

  handleChangePersonalization(customizationId, value) {
    const { personalizations } = this.state;
    const updatedIdx = personalizations.findIndex(
      (p) => parseInt(p.customization_id) === parseInt(customizationId),
    );
    const personalization = personalizations[updatedIdx];

    if (personalization.type === "files" && !validFile(value)) {
      alert(
        "Невалиден файлов формат. Моля изберете изображение във формат JPEG, PNG, TIFF или BMP. ",
      );

      const fields = document.querySelectorAll(`#files_${customizationId}`);

      for (let j = 0, fieldsLen = fields.length; j < fieldsLen; j++) {
        const field = fields[j];

        field.value = null;
      }

      return;
    }

    const newPersonalizations = [
      ...personalizations.slice(0, updatedIdx),
      Object.assign({}, personalizations[updatedIdx], { value }),
      ...personalizations.slice(updatedIdx + 1),
    ];

    this.setState({ personalizations: newPersonalizations }, () =>
      this.notifyChange(),
    );
  }

  handleToggle(customizationId) {
    const { personalizations } = this.state;
    const updatedIdx = personalizations.findIndex(
      (p) => parseInt(p.customization_id) === parseInt(customizationId),
    );

    const newPersonalizations = [
      ...personalizations.slice(0, updatedIdx),
      Object.assign({}, personalizations[updatedIdx], {
        active: !personalizations[updatedIdx].active,
      }),
      ...personalizations.slice(updatedIdx + 1),
    ];

    this.setState({ personalizations: newPersonalizations }, () =>
      this.notifyChange(),
    );
  }

  notifyChange() {
    if (this.props.onChange) this.props.onChange(this.state.personalizations);
  }

  render() {
    const { personalizations } = this.state;

    const freePersonalizations = personalizations
      .filter((c) => c.free === true)
      .sort((a, b) => boolCompare(a.mandatory, b.mandatory));
    const additionalPersonalizations = personalizations.filter(
      (c) => c.free === false,
    );

    return (
      <div className="product-personalizations">
        {freePersonalizations.length > 0 && (
          <div className="personalization-wrapper">
            <h5>
              {this.props.product.product_type === "pre_made"
                ? "Изберете от следните опции"
                : "Опции за изработка на дизайн (безплатни)"}
            </h5>
            <div className="included-personalization-wrapper">
              <PersonalizationEditor
                personalizations={freePersonalizations}
                onChange={(customizationId, value) =>
                  this.handleChangePersonalization(customizationId, value)
                }
                onToggle={(customizationId) =>
                  this.handleToggle(customizationId)
                }
              />
            </div>
          </div>
        )}

        {additionalPersonalizations.length > 0 && (
          <div className="personalization-wrapper">
            <h5>Допълнителни опции за изработка (платени)</h5>
            <PersonalizationEditor
              personalizations={additionalPersonalizations}
              onChange={(customizationId, value) =>
                this.handleChangePersonalization(customizationId, value)
              }
              onToggle={(customizationId) => this.handleToggle(customizationId)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Personalizations;
