import React from "react";
import { EmbeddedVideo } from "@raketa-cms/raketa-cms";

export default ({ videoUrl, variant }) => (
  <div className="video">
    <div className={`video-wrapper ${variant}`}>
      <EmbeddedVideo videoUrl={videoUrl} />
    </div>
  </div>
);
