import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  Img,
  TextInput,
  SelectMenu,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";

const Thumbs = ({ list }) => (
  <div className="row">
    {list.map((item, idx) => (
      <div className="col-3" key={idx}>
        <a href={item.link} className="category-item">
          <div className="title-wrapper">
            <h2 className="category-title">{item.title}</h2>
          </div>

          <Img src={item.image} variant="square" />
        </a>
      </div>
    ))}
  </div>
);

const ThumbsContained = ({ list }) => (
  <div className="row">
    <div className="shift-2">
      <div className="row">
        {list.map((item, idx) => (
          <div className="col-6" key={idx}>
            <a href={item.link} className="category-item">
              <div className="title-wrapper">
                <h2 className="category-title">{item.title}</h2>
              </div>

              <Img src={item.image} variant="square" />
            </a>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SquareThumbsWidget = ({ list, variant, containerSettings }) => (
  <Container className="category-feed" settings={containerSettings}>
    <div className="container">
      {variant === "normal" ? (
        <Thumbs list={list} />
      ) : (
        <ThumbsContained list={list} />
      )}
    </div>
  </Container>
);

SquareThumbsWidget.title = "Square Thumbs";
SquareThumbsWidget.category = "General";
SquareThumbsWidget.dialogSize = "large";

SquareThumbsWidget.propTypes = {
  list: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SquareThumbsWidget.defaults = {
  list: [
    {
      id: 1,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      link: "#",
    },
    {
      id: 2,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      link: "#",
    },
    {
      id: 3,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      link: "#",
    },
    {
      id: 4,
      image: imagePlaceholder("430x430"),
      title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
      link: "#",
    },
  ],
  variant: "normal",
  containerSettings: {},
};

const SquareFields = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

SquareFields.defaultProps = {
  settings: {},
};

SquareFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

SquareThumbsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["normal", "Normal"],
        ["shifted", "Shifted"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      label="Thumbs"
      listItem={(iteSettings, onChangeItem) => (
        <SquareFields settings={iteSettings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      template={[
        {
          image: imagePlaceholder("430x430"),
          title: "КАПАЦИ ЗА ТЕЛЕФОНИ И ЛАПТОПИ",
          link: "#",
        },
      ]}
      primaryField="title"
    />
  </div>
);

export default SquareThumbsWidget;
