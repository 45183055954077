import React, { useState } from "react";
import MdSearchIcon from "react-icons/lib/md/search";
import { Dialog, RaketaUIProvider } from "@raketa-cms/raketa-cms";
import Autocomplete from "../Autocomplete";
import MaterialFields from "./MaterialFields";
import { post } from "../../helpers/http";
import DialogWrapper from "../../DialogWrapper";

const remoteCreate = (builtMaterial, createMaterialUrl, csrfToken, setters) => {
  const body = {
    warehouse: {
      name: builtMaterial.name,
      code: builtMaterial.code,
      min_quantity: builtMaterial.minQty,
      location_id: builtMaterial.location,
      quantity: builtMaterial.quantity,
      reference: builtMaterial.reference,
    },
  };

  post(createMaterialUrl, body, {
    headers: { "X-CSRF-Token": csrfToken, "content-type": "application/json" },
  })
    .then((response) => {
      if (response.ok || response.status === 422) {
        return response.json();
      }

      throw Error("Something went wrong!");
    })
    .then((parsedResponse) => {
      if (parsedResponse.hasOwnProperty("errors")) {
        setters.setErrors(parsedResponse.errors);
      } else {
        setters.setErrors([]);
        setters.setMaterial(parsedResponse);
        setters.setIsOpenCreate(false);
        setters.setIsOpen(false);
      }
    })
    .catch((error) => setters.setErrors([error.message]));
};

const materialToString = (material) =>
  material && `${material.name} - ${material.code}`;

const MaterialPicker = ({
  materials,
  createMaterialUrl,
  name,
  initialMaterial,
  locations,
  csrfToken,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [material, setMaterial] = useState(
    initialMaterial || { id: "", code: "" },
  );
  const [errors, setErrors] = useState([]);
  const [builtMaterial, setBuiltMaterial] = useState({});

  return (
    <RaketaUIProvider>
      <div className="material-picker">
        <label htmlFor="">Материал</label>

        <div className="material-label" onClick={() => setIsOpen(true)}>
          <span className="search-icon">
            <MdSearchIcon />
          </span>
          {material.id > 0 ? materialToString(material) : ""}
        </div>

        <DialogWrapper open={isOpen}>
          <Dialog
            open={isOpen}
            title="Търсене или добавяне на материал"
            primaryLabel="Затваряне"
            secondaryLabel={false}
            width="700px"
            onPrimary={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
          >
            <div className="search-dialog-content">
              <Autocomplete
                initialItems={materials}
                itemToString={materialToString}
                initialSelectedItem={material.id > 0 ? material : null}
                placeholder="Търси по име"
                onSelected={(newMaterial) => setMaterial(newMaterial)}
              />

              <p>
                Няма намерени материали.{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpenCreate(true);
                  }}
                >
                  Създай нов
                </a>
              </p>
            </div>
          </Dialog>
        </DialogWrapper>

        <DialogWrapper open={isOpenCreate}>
          <Dialog
            open={isOpenCreate}
            title="Нов материал"
            primaryLabel="Запазване"
            secondaryLabel={false}
            width="400px"
            onPrimary={() =>
              remoteCreate(builtMaterial, createMaterialUrl, csrfToken, {
                setIsOpen,
                setIsOpenCreate,
                setMaterial,
                setErrors,
              })
            }
            onClose={() => setIsOpenCreate(false)}
          >
            <MaterialFields
              errors={errors}
              locations={locations}
              onChange={(newMaterial) => setBuiltMaterial(newMaterial)}
            />
          </Dialog>
        </DialogWrapper>

        <input type="hidden" name={name} value={material.id} />
      </div>
    </RaketaUIProvider>
  );
};

export default MaterialPicker;
