import React from "react";
import { FormGroup, Label } from "@raketa-cms/raketa-cms";
import Autocomplete from "../fields/Autocomplete";

const ProductPreview = ({ product }) => (
  <div>
    <strong>{product.name}</strong> -{" "}
    <a href={product.full_slug} target="_blank">
      link
    </a>
  </div>
);

class ProductPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: props.value || {},
    };
  }

  handleChangeProduct(newProduct) {
    this.setState({ product: newProduct }, () => this.notifyChange());
  }

  notifyChange() {
    const { onChange } = this.props;
    const { product } = this.state;

    onChange(product);
  }

  render() {
    const { label } = this.props;
    const { product } = this.state;

    return (
      <FormGroup>
        <Label>{label}</Label>

        <Autocomplete
          searchUrl="/cms/products/search"
          searchTermKey="product_info"
          itemToString={(item) => item.name}
          initialSelectedItem={product}
          onSelected={(newProduct) => this.handleChangeProduct(newProduct)}
        />

        {product && <ProductPreview product={product} />}
      </FormGroup>
    );
  }
}

ProductPicker.defaultProps = {
  label: "Product",
};

export default ProductPicker;
