import React from "react";
import activePersonalizations from "./activePersonalizations";
import personalizationName from "./personalizationName";

const getValue = (p) => {
  if (p.type === "files") return `${JSON.parse(p.value).length} бр.`;

  return p.value;
};

const ProductPersonalizations = ({
  personalizationNames,
  personalizations,
}) => {
  if (activePersonalizations(personalizations).length === 0) return null;

  return (
    <div className="personalization-list">
      {activePersonalizations(personalizations).map((p) => (
        <div key={p.customization_id}>
          <strong>
            {personalizationName(personalizationNames, p.customization_id)}:
          </strong>{" "}
          {getValue(p)}
        </div>
      ))}
    </div>
  );
};

export default ProductPersonalizations;
