import React, { useState } from "react";
import Downshift from "downshift";
import { get } from "../helpers/http";

const shouldInitiallyOpen = (searchTerms, initialSelectedItem) => {
  return (
    Object.keys(searchTerms).length > 0 &&
    (!initialSelectedItem || Object.keys(initialSelectedItem).length === 0)
  );
};

const RemoteAutocomplete = ({
  searchUrl,
  searchTermKey,
  itemToString,
  initialSelectedItem,
  onSelected,
  placeholder,
  additionalSearchTerms = {},
  ...rest
}) => {
  const [items, setItems] = useState([]);
  const [initialyOpen, setInitialyOpen] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;

    if (shouldInitiallyOpen(additionalSearchTerms, initialSelectedItem)) {
      get(`${searchUrl}.json`, { ...additionalSearchTerms })
        .then((response) => response.json())
        .then((data) => {
          if (mounted) {
            setItems(data);
            if (data.length > 0) {
              setInitialyOpen(true);
            }
          }
        });
    }

    return () => (mounted = false);
  }, [additionalSearchTerms]);

  const onInputChange = (event) => {
    const term = event.target.value;
    const key = `f[${searchTermKey}]`;
    setItems([]);

    if (term.length >= 3) {
      get(`${searchUrl}.json`, { ...additionalSearchTerms, [key]: term })
        .then((response) => response.json())
        .then((data) => setItems(data));
    }
  };

  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      itemToString={(item) => (item ? itemToString(item) : "")}
      onChange={(selected) => {
        onSelected(selected);
        setInitialyOpen(false);
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        selectedItem,
      }) => (
        <div className="autocomplete">
          <input
            {...getInputProps({
              onChange: onInputChange,
              placeholder,
              ...rest,
            })}
          />
          <ul {...getMenuProps()}>
            {isOpen || initialyOpen
              ? items.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item.id,
                      title: itemToString(item),
                      index,
                      item,
                      style: {},
                    })}
                  >
                    {itemToString(item)}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

const LocalAutocomplete = ({
  itemToString,
  initialSelectedItem,
  onSelected,
  placeholder,
  initialItems,
}) => {
  const [items, setItems] = useState(initialItems);
  const [focused, setFocused] = useState(false);
  const onInputFocus = (_event) => setFocused(true);
  const onInputBlur = (_event) => setFocused(false);
  const onInputChange = (event) => {
    const term = event.target.value;
    const searchTerm = term ? term.toLowerCase() : "";

    if (searchTerm) {
      setItems(
        initialItems.filter((item) =>
          itemToString(item).toLowerCase().includes(searchTerm),
        ),
      );
    } else {
      setItems(initialItems);
    }
  };

  const inputOptions = {
    onChange: onInputChange,
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    placeholder,
  };

  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      itemToString={(item) => (item ? itemToString(item) : "")}
      onChange={(selected) => onSelected(selected)}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen }) => (
        <div className="autocomplete">
          <input {...getInputProps(inputOptions)} />
          <ul {...getMenuProps()}>
            {focused || isOpen
              ? items.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item.id,
                      title: itemToString(item),
                      index,
                      item,
                      style: {},
                    })}
                  >
                    {itemToString(item)}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

export default (props) => {
  const { searchUrl } = props;

  if (!searchUrl) {
    return <LocalAutocomplete {...props} />;
  }

  return <RemoteAutocomplete {...props} />;
};
