import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Img } from "@raketa-cms/raketa-cms";

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
      lighboxOpen: false,
    };
  }

  render() {
    const { images, freeDelivery } = this.props;
    const { currentImageIdx, lighboxOpen } = this.state;
    const galleryImages = images.map((img) => {
      return { src: img.urls.image };
    });

    return (
      <div className="product-gallery">
        {freeDelivery && <i className="free-dellivery-badge" />}

        <div className="product-gallery-main-image">
          <button
            type="button"
            onClick={() => this.setState({ lighboxOpen: true })}
          >
            <Img src={images[currentImageIdx]} variant="square" />
          </button>

          <ModalGateway>
            {lighboxOpen ? (
              <Modal onClose={() => this.setState({ lighboxOpen: false })}>
                <Carousel
                  views={galleryImages}
                  currentIndex={currentImageIdx}
                  trackProps={{
                    onViewChange: (currentView) =>
                      this.setState({ currentImageIdx: currentView }),
                  }}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>

        <div className="product-gallery-image-list">
          {images.map((img, idx) => (
            <button
              key={idx}
              onClick={() => this.setState({ currentImageIdx: idx })}
            >
              <Img src={img} variant="square" />
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default Gallery;
