import React from "react";
import CheckoutCart from "./Cart";
import PaymentData from "./PaymentData";
import PersonalData from "./PersonalData";
import DeliveryData from "./DeliveryData";
import PoliciesData from "./PoliciesData";
import CheckoutSection from "./CheckoutSection";
import Voucher from "./Voucher";
import OrderSummary from "./OrderSummary";
import EmptyState from "./EmptyState";

const handleSubmit = (event) => {
  event.preventDefault();

  const form = event.target;

  const submitButtons = form.querySelectorAll('button[type="submit"]');

  submitButtons.forEach((button) => (button.disabled = true));

  form.submit();
};

const onlyOnDeliveryPayment = (deliveryMethod, couriers) => {
  const courier = couriers.find((c) => c.id === deliveryMethod);

  if (!courier) {
    return false;
  }

  if (courier.address_type === "office" && courier.base_courier === "econt") {
    return true;
  }

  return false;
};

export default function NewCheckout({
  cart,
  couriers,
  customer,
  csrf,
  submitUrl,
  paymentMethods,
  formData,
  randomCategories,
}) {
  if (cart.cart_items.length === 0) {
    return <EmptyState categories={randomCategories} />;
  }

  const initialState = {
    cart: { ...cart },
    personal: {
      name: formData.personal.name,
      email: formData.personal.email,
      phone: formData.personal.phone,
      invoice: formData.personal.invoice,
      invoiceInfo: formData.personal.invoiceInfo,
      gift: formData.personal.gift,
      recipientName: formData.personal.recipientName,
      recipientPhone: formData.personal.recipientPhone,
      note: formData.personal.note,
    },
    payment: {
      method: formData.payment.method,
    },
    delivery: {
      method: formData.delivery.method,
      city: formData.delivery.city,
      address: formData.delivery.address,
      courierMeta: formData.delivery.courierMeta?.office || {},
      cityMeta: formData.delivery.cityMeta || {},
    },
    voucher: {
      code: formData.voucher.code,
      freeDelivery: formData.voucher.freeDelivery,
      message: formData.voucher.message,
      error: formData.voucher.error,
    },
    checkboxes: {
      privacy: formData.checkboxes.privacy,
      newsletter: formData.checkboxes.newsletter,
    },
  };

  const [state, setState] = React.useState(initialState);

  const handleChange = (namespace, field, value) => {
    setState({
      ...state,
      [namespace]: {
        ...state[namespace],
        [field]: value,
      },
    });
  };

  const handleMultiChange = (items) => {
    let newState = { ...state };

    items.forEach((item) => {
      const { namespace, key, value } = item;

      newState = {
        ...newState,
        [namespace]: {
          ...newState[namespace],
          [key]: value,
        },
      };
    });

    setState({ ...newState });
  };

  const changeCartItem = (item) => {
    fetch(`/store/cart/${item.id}`, {
      method: "PATCH",
      headers: { "X-CSRF-Token": csrf, "content-type": "application/json" },
      body: JSON.stringify({ quantity: item.quantity }),
    })
      .then((r) => r.json())
      .then((newCart) => setState({ ...state, cart: newCart }))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteCartItem = (item) => {
    fetch(`/store/cart/${item.id}`, {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrf, "content-type": "application/json" },
    })
      .then((r) => r.json())
      .then((newCart) => setState({ ...state, cart: newCart }))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const applyVoucher = (code) => {
    fetch(`/store/vouchers/${code}`, {
      method: "GET",
      headers: { "X-CSRF-Token": csrf, "content-type": "application/json" },
    })
      .then((r) => r.json())
      .then((voucher) => {
        if (!voucher || (voucher && !voucher.active)) {
          setState({
            ...state,
            voucher: {
              code: code,
              error: voucher
                ? voucher.voucher_message
                : "Невалиден код за отстъпка.",
              freeDelivery: false,
              message: "",
            },
          });
          return;
        } else {
          setState({
            ...state,
            voucher: {
              code: code,
              message: voucher.voucher_info,
              freeDelivery: voucher.free_delivery,
              discountType: voucher.discount_type,
              discountPercentage: voucher.discount_percentage,
              discount: voucher.discount,
              error: "",
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="new-checkout">
      <div className="container">
        <div className="spacing-both">
          <h2 className="text-center">Поръчка</h2>
        </div>

        <div className="spacing-bottom">
          <button className="btn-primary" type="submit">
            Ако имате регистрация логнете се тук
          </button>
        </div>

        <form method="post" action={submitUrl} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-8">
              <div className="spacing-bottom">
                <CheckoutSection title="Вашите данни" variant="lighter">
                  <PersonalData
                    data={state.personal}
                    errors={formData.errors}
                    customer={customer}
                    onChange={(key, value) =>
                      handleChange("personal", key, value)
                    }
                  />
                </CheckoutSection>

                <CheckoutSection title="Метод на плащане" variant="lighter">
                  <PaymentData
                    data={state.payment}
                    paymentMethods={paymentMethods}
                    onlyOnDelivery={onlyOnDeliveryPayment(
                      state.delivery.method,
                      couriers,
                    )}
                    onChange={(key, value) =>
                      handleChange("payment", key, value)
                    }
                  />
                </CheckoutSection>

                <DeliveryData
                  couriers={couriers}
                  data={state.delivery}
                  onChange={(key, value) => {
                    if (
                      key === "method" &&
                      onlyOnDeliveryPayment(value, couriers)
                    ) {
                      handleMultiChange([
                        {
                          namespace: "delivery",
                          key,
                          value,
                        },
                        {
                          namespace: "payment",
                          key: "method",
                          value: "on_delivery",
                        },
                      ]);
                    } else {
                      handleChange("delivery", key, value);
                    }
                  }}
                />
              </div>
            </div>

            <div className="col-4">
              <aside className="sidebar">
                <div className="spacing-bottom">
                  <CheckoutCart
                    cart={state.cart}
                    onChangeItem={(item) => changeCartItem(item)}
                    onDeleteItem={(item) => deleteCartItem(item)}
                  />
                </div>

                <CheckoutSection title="Ваучер" variant="monochrome">
                  <Voucher
                    data={state.voucher}
                    onChange={(key, value) =>
                      handleChange("voucher", key, value)
                    }
                    onApply={() => applyVoucher(state.voucher.code)}
                  />
                </CheckoutSection>

                <OrderSummary
                  {...state}
                  couriers={couriers}
                  customer={customer}
                />

                <PoliciesData
                  data={state.checkboxes}
                  onChange={(key, value) =>
                    handleChange("checkboxes", key, value)
                  }
                />

                <input type="hidden" name="authenticity_token" value={csrf} />

                {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}

                <div className="checkout-button-wrapper">
                  <button className="btn-primary" type="submit">
                    Поръчай
                  </button>
                </div>
              </aside>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
