import React from "react";

const Checkbox = ({
  label,
  name,
  value,
  checked,
  errors,
  onChange,
  ...rest
}) => (
  <label className={`checkbox-row ${errors ? "has-error" : ""}`}>
    <input
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      onChange={(e) => onChange(e.target.checked, e)}
      {...rest}
    />
    {label}
    {errors && <span className="help-block">{errors.join(", ")}</span>}
  </label>
);

const Radio = ({ label, name, value, checked, onChange, ...rest }) => (
  <label className="checkbox-row">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={(e) => onChange(e.target.checked, e)}
      {...rest}
    />
    {label}
  </label>
);

const TextField = ({
  label,
  type = "text",
  value,
  errors,
  onChange,
  ...rest
}) => (
  <div className={`form-group ${errors ? "has-error" : ""}`}>
    <label className="control-label">{label}</label>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      className="form-control"
      {...rest}
    />
    {errors && <span className="help-block">{errors.join(", ")}</span>}
  </div>
);

const TextareaField = ({ label, value, errors, onChange, ...rest }) => (
  <div className={`form-group ${errors ? "has-error" : ""}`}>
    <label className="control-label">{label}</label>
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      className="form-control"
      {...rest}
    ></textarea>
    {errors && <span className="help-block">{errors.join(", ")}</span>}
  </div>
);

export { Checkbox, Radio, TextField, TextareaField };
