import React from "react";
import { RichText } from "@raketa-cms/raketa-rte";

export default class RichEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || "",
    };
  }
  render() {
    const { label, name } = this.props;
    const { value } = this.state;

    return (
      <div className="rich-editor">
        <RichText
          label={label}
          value={value}
          onChange={(value) => this.setState({ value })}
        />

        <input type="hidden" name={name} value={value} />
      </div>
    );
  }
}
