import ProductFeedWidget from "./ProductFeedWidget/ProductFeedWidget";
import SectionTitleWidget from "./SectionTitleWidget";
import ImageWidget from "./ImageWidget";
import SliderWidget from "./SliderWidget";
import CategoryFeedWidget from "./CategoryFeedWidget";
import CtaButtonWidget from "./CtaButtonWidget";
import PageTitleWidget from "./PageTitleWidget";
import StoreFeedWidget from "./StoreFeedWidget";
import TextBoxWidget from "./TextBoxWidget";
import SeparatorWidget from "./SeparatorWidget";
import NavigationWidget from "./NavigationWidget";
import SquareThumbsWidget from "./SquareThumbsWidget";
import VideoWidget from "./VideoWidget";
import GalleryWidget from "./GalleryWidget";

export default {
  SectionTitleWidget,
  ImageWidget,
  ProductFeedWidget,
  CategoryFeedWidget,
  CtaButtonWidget,
  PageTitleWidget,
  SliderWidget,
  StoreFeedWidget,
  TextBoxWidget,
  SeparatorWidget,
  NavigationWidget,
  SquareThumbsWidget,
  VideoWidget,
  GalleryWidget,
};
