import React from "react";

const CheckoutSection = ({ title, variant = "", children }) => (
  <div className="spacing-bottom">
    <div className={`checkout-section ${variant}`}>
      <h4>{title}</h4>
      <div className="fields">{children}</div>
    </div>
  </div>
);

export default CheckoutSection;
