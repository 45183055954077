import React from "react";
import FilePicker from "./FilePicker";

const PersonalizationField = ({
  type,
  name,
  value,
  active,
  options,
  customizationId,
  onChange,
}) => {
  if (type === "text") {
    return (
      <div>
        <input
          type="text"
          name={name}
          placeholder="Моля, въведете Вашия текст..."
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }

  if (type === "menu") {
    return (
      <div className="styled-select">
        <select
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">Please select...</option>
          {options.map((o, idx) => (
            <option key={idx} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === "files") {
    return (
      <div>
        <FilePicker
          name={name}
          customizationId={customizationId}
          onChange={onChange}
        />
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div>
        <input type="hidden" name={name} value={active ? 1 : 0} />
      </div>
    );
  }

  return null;
};

export default PersonalizationField;
