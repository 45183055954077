import React from "react";
import AutoComplete from "./Autocomplete";

const randomString = (len, an) => {
  an = an && an.toLowerCase();
  var str = "",
    i = 0,
    min = an == "a" ? 10 : 0,
    max = an == "n" ? 10 : 62;
  for (; i++ < len; ) {
    var r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
};

export default class ProductCustomizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items.length > 0 ? props.items : [],
      selectedCustomization: null,
      selectedFree: 0,
      desiredPrice: 0,
      selectedMandatory: 0,
    };
  }

  handleAddCustomization() {
    const {
      selectedCustomization,
      selectedFree,
      desiredPrice,
      selectedMandatory,
      items,
    } = this.state;

    if (selectedCustomization === null) return; // No customization selected
    if (
      items.find(
        (item) => parseInt(item.customization.id) === selectedCustomization.id,
      )
    )
      return;

    this.setState({
      items: [
        ...items,
        {
          id: randomString(4),
          customization: { ...selectedCustomization },
          free: parseInt(selectedFree) === 1,
          mandatory: parseInt(selectedMandatory) === 1,
          price: desiredPrice,
        },
      ],
      selectedCustomization: null,
      selectedFree: 0,
      desiredPrice: 0,
      selectedMandatory: 0,
    });
  }

  handleRemoveCustomization(id) {
    if (!confirm("Are you sure? ")) return;

    const { items } = this.state;
    const itemIdx = items.findIndex(
      (item) => parseInt(item.id) === parseInt(id),
    );

    this.setState({
      items: items.filter((_item, idx) => idx !== itemIdx),
    });
  }

  renderRows(items) {
    if (items.length === 0)
      return (
        <tr>
          <td colSpan="4">Без персонализации</td>
        </tr>
      );

    return items.map((item, idx) => (
      <tr key={item.id}>
        <td>{item.customization.name}</td>
        <td>{item.free ? "Yes" : "No"}</td>
        <td>{item.mandatory ? "Yes" : "No"}</td>
        <td>{item.price > 0 ? `+${item.price} лв.` : ""}</td>
        <td>
          <button
            type="button"
            className="btn-danger btn-sm"
            onClick={() => this.handleRemoveCustomization(item.id)}
          >
            &times;
          </button>

          <input
            type="hidden"
            name={`product[product_customizations][${idx}][customization_id]`}
            value={item.customization.id}
          />
          <input
            type="hidden"
            name={`product[product_customizations][${idx}][free]`}
            value={item.free ? 1 : 0}
          />
          <input
            type="hidden"
            name={`product[product_customizations][${idx}][mandatory]`}
            value={item.mandatory ? 1 : 0}
          />
          <input
            type="hidden"
            name={`product[product_customizations][${idx}][price]`}
            value={item.price}
          />
        </td>
      </tr>
    ));
  }

  render() {
    const { items, selectedFree, selectedMandatory, desiredPrice } = this.state;
    const { customizations } = this.props;

    return (
      <div className="product-customizations">
        <label className="control-label">Персонализации</label>

        <table>
          <thead>
            <tr>
              <th>Персонализация</th>
              <th>Безплатна</th>
              <th>Задължителна</th>
              <th>Цена</th>
              <th className="actions">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{this.renderRows(items)}</tbody>
        </table>

        <label className="control-label">Добавяне на персонализация</label>

        <div className="add-customization">
          <AutoComplete
            initialItems={customizations}
            itemToString={(item) =>
              `${item.name}${item.note ? ` - ${item.note}` : ""}`
            }
            onSelected={(item) =>
              this.setState({ selectedCustomization: item })
            }
          />

          <select
            value={selectedFree}
            onChange={(e) => this.setState({ selectedFree: e.target.value })}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>

          <select
            value={selectedMandatory}
            onChange={(e) =>
              this.setState({ selectedMandatory: e.target.value })
            }
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>

          <input
            type="number"
            className="form-control"
            value={desiredPrice}
            onChange={(e) => this.setState({ desiredPrice: e.target.value })}
          />

          <button
            type="button"
            className="btn-primary btn-sm"
            onClick={() => this.handleAddCustomization()}
          >
            +
          </button>
        </div>
      </div>
    );
  }
}
