import React from "react";
import Step from "../Step";
import DeliveryStep from "./DeliveryStep";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: props.cart || { total: 0, cart_items: [] },
      deliveryMethod: props.deliveryMethod || "",
      deliveryAddress: props.address || "",
      deliveryCity: props.city || "",
      freeDelivery: props.cart
        ? props.cart.cart_items.some((ci) => ci.product_free_delivery === true)
        : false,
      courierId: props.courierId,
    };
  }

  getCourier() {
    const { couriers } = this.props;
    const { courierId } = this.state;

    return couriers.find((c) => parseInt(c.id, 10) === parseInt(courierId, 10));
  }

  render() {
    const {
      customer,
      couriers,
      deliveryDescription,
      backUrl,
      submitUrl,
      csrf,
    } = this.props;

    const {
      deliveryMethod,
      freeDelivery,
      courierId,
      deliveryAddress,
      deliveryCity,
    } = this.state;

    const selectedCourier = this.getCourier();

    return (
      <div className="checkout">
        <Step
          title="Получаване или доставка"
          step="delivery"
          active
          showCustomer={!customer}
        >
          <DeliveryStep
            csrf={csrf}
            backUrl={backUrl}
            submitUrl={submitUrl}
            customer={customer}
            deliveryMethod={deliveryMethod}
            freeDelivery={freeDelivery}
            couriers={couriers}
            courierId={courierId}
            deliveryDescription={deliveryDescription}
            selectedCourier={selectedCourier}
            deliveryAddress={deliveryAddress}
            deliveryCity={deliveryCity}
            onChange={(field, value) => this.setState({ [field]: value })}
            onCourierDelivery={(deliveryMethod) =>
              this.setState({ deliveryMethod })
            }
          />
        </Step>
      </div>
    );
  }
}

export default Checkout;
