import React from "react";

const handleGoto = (e, elementId) => {
  e.preventDefault();

  $("html, body").animate(
    {
      scrollTop: $(elementId).offset().top,
    },
    1000,
  );
};

const Link = ({ elementId, content }) => (
  <a href={elementId} onClick={(e) => handleGoto(e, elementId)}>
    {content}
  </a>
);

const LinkWithIcon = ({ elementId, content, icon }) => (
  <a href={elementId} onClick={(e) => handleGoto(e, elementId)}>
    <i className={icon}></i>
    <span>{content}</span>
  </a>
);

export default ({ product }) => (
  <div className="navigation">
    <div className="mobile">
      {product.content && (
        <LinkWithIcon
          elementId="#full-description"
          content="Виж пълното описание"
          icon="icon-arrow-down"
        />
      )}
      {product.video_url && (
        <LinkWithIcon
          elementId="#product-video"
          content="Виж видео"
          icon="icon-video"
        />
      )}
    </div>

    <div className="desktop">
      {product.content && (
        <Link elementId="#full-description" content="Виж пълното описание" />
      )}
    </div>
  </div>
);
