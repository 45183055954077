import React, { useState, useEffect } from "react";
import { TextInput, SelectMenu } from "@raketa-cms/raketa-cms";

const Errors = ({ messages }) => {
  if (messages.length === 0) return null;

  return (
    <ul className="error-messages">
      {messages.map((error, idx) => (
        <li key={idx}>{error}</li>
      ))}
    </ul>
  );
};

const locationOptions = (locations) =>
  locations.map((loc) => [loc.id, loc.name]);

const MaterialFields = ({ errors, locations, onChange }) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [minQty, setMinQty] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [location, setLocation] = useState("");
  const [reference, setReference] = useState("");

  useEffect(() => {
    onChange({ name, code, minQty, quantity, location, reference });
  }, [name, code, minQty, quantity, location, reference]);

  return (
    <div>
      <Errors messages={errors} />

      <TextInput
        label="Име"
        value={name}
        onChange={(value) => setName(value)}
      />

      <TextInput
        label="Код"
        value={code}
        onChange={(value) => setCode(value)}
      />

      <TextInput
        label="Мин. Количество"
        value={minQty}
        onChange={(value) => setMinQty(value)}
      />

      <TextInput
        label="Първоначална наличност"
        value={quantity}
        onChange={(value) => setQuantity(value)}
      />

      <SelectMenu
        label="Местоположение"
        options={locationOptions(locations)}
        value={location}
        onChange={(value) => setLocation(value)}
      />

      <TextInput
        label="Референция"
        value={reference}
        onChange={(value) => setReference(value)}
      />
    </div>
  );
};

export default MaterialFields;
