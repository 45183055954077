import React from "react";
import { Container, EmbeddedVideo } from "@raketa-cms/raketa-cms";

const VideoWidget = ({ videoURL, variant, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="video">
      <div className="container">
        <div className="row">
          <div className={`video-wrapper ${variant}`}>
            <EmbeddedVideo videoUrl={videoURL} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

VideoWidget.title = "Video";
VideoWidget.category = "Media";
VideoWidget.primaryField = "videoURL";
VideoWidget.dialogSize = "large";

VideoWidget.defaults = {
  videoURL: "https://www.youtube.com/watch?v=7Glc2mHLPRM",
  variant: "col-12",
  containerSettings: {},
};

VideoWidget.adminFields = {
  videoURL: { type: "text" },
  variant: {
    type: "select",
    options: [
      ["col-12", "12 columns"],
      ["col-8", "8 columns"],
      ["shift-2", "Shift 2"],
    ],
  },
};

export default VideoWidget;
