import React from "react";

class FilePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [null],
    };
  }

  handleAdd() {
    const { items } = this.state;

    const newItems = [...items, null];

    this.setState({ items: newItems }, () => {
      this.props.onChange(this.state.items);
    });
  }

  handleChange(file, idx) {
    const { items } = this.state;

    const newItems = [...items.slice(0, idx), file, ...items.slice(idx + 1)];

    this.setState({ items: newItems }, () => {
      this.props.onChange(this.state.items);
    });
  }

  handleDelete(idx) {
    const { items } = this.state;

    if (items.length < 2) return;

    const newItems = [...items.slice(0, idx), ...items.slice(idx + 1)];

    this.setState({ items: newItems }, () => {
      this.props.onChange(this.state.items);
    });
  }

  render() {
    const { name, customizationId, disabled } = this.props;
    const { items } = this.state;

    return (
      <div className="file-picker">
        <div className="picker-hint">
          <small>Максимален размер на снимка до 10MB. </small>
        </div>

        {items.map((f, idx) => (
          <div className="file-item" key={idx}>
            <input
              type="file"
              name={`${name}[]`}
              id={`files_${customizationId}`}
              disabled={disabled}
              onChange={(e) => this.handleChange(e.target.value, idx)}
            />
            {idx > 0 && (
              <button
                type="button"
                className="remove-file"
                onClick={() => this.handleDelete(idx)}
              >
                Премахни
              </button>
            )}
          </div>
        ))}

        <button
          type="button"
          className="add-file"
          onClick={() => this.handleAdd()}
        >
          + Добави още един файл
        </button>
      </div>
    );
  }
}

export default FilePicker;
