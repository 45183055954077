import React from "react";
import { Checkbox } from "./Inputs";

const PoliciesData = ({ data, onChange }) => (
  <React.Fragment>
    <div className="policy">
      <Checkbox
        label={
          <p>
            Съгласен съм с{" "}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              общите условия
            </a>
          </p>
        }
        checked={data.privacy}
        required
        name="order[terms_of_service]"
        value="1"
        onChange={(checked) => onChange("privacy", checked)}
      />

      <Checkbox
        label="Искам да се абонирам за месечния бюлетин"
        checked={data.newsletter}
        name="order[newsletter]"
        value="1"
        onChange={(checked) => onChange("newsletter", checked)}
      />
    </div>
  </React.Fragment>
);

export default PoliciesData;
