/* eslint-disable no-undef */
const serialize = (params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");

const get = (url, params = {}, opts = {}) =>
  fetch(`${url}?${serialize(params)}`, opts);
const patch = (url, params = {}, opts = {}) =>
  fetch(
    url,
    Object.assign(
      {
        method: "PATCH",
        credentials: "same-origin",
        body: JSON.stringify(params),
      },
      opts,
    ),
  );
const post = (url, params = {}, opts = {}) =>
  fetch(
    url,
    Object.assign(
      {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify(params),
      },
      opts,
    ),
  );
const del = (url, params = {}, opts = {}) =>
  fetch(
    url,
    Object.assign(
      {
        method: "DELETE",
        credentials: "same-origin",
        body: JSON.stringify(params),
      },
      opts,
    ),
  );

export { get, patch, post, del };
