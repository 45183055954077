import React from "react";
import { Img } from "@raketa-cms/raketa-cms";
import Currency from "../../../../pages/ProductView/Currency";
import ProductPersonalizations from "../../ProductPersonalizations";

const handleSubmit = (event) => {
  event.preventDefault();

  const form = event.target;

  const submitButtons = form.querySelectorAll('button[type="submit"]');

  submitButtons.forEach((button) => (button.disabled = true));

  form.submit();
};

const shouldHaveVoucherDiscount = (voucher, customer) => {
  if (customer && customer.discount_percentage > 0) return false;

  return voucher && voucher.discount;
};

const shouldHaveCustomerdiscount = (customer) =>
  customer && customer.discount_percentage > 0;

const SubmitDisabled = (cartFilesSize) => cartFilesSize > 30;

const FilesSizeMessage = ({ cartFilesSize }) => {
  if (cartFilesSize > 30) {
    return (
      <div className="large-file-notice">
        Поръчката ви съдържа файлове с обща големина {cartFilesSize} MB. Не може
        да продължите. Моля изпратете поръчката си на{" "}
        <a href="mailto:unikalni.podaraci@gmail.com">
          unikalni.podaraci@gmail.com
        </a>
      </div>
    );
  }

  if (cartFilesSize > 10) {
    return (
      <div className="large-file-notice">
        Поръчката ви съдържа големи файлове, моля изчакайте търпеливо докато те
        се качват. Ако възникат проблеми при изпращането, моля изпратете
        поръчката си на{" "}
        <a href="mailto:unikalni.podaraci@gmail.com">
          unikalni.podaraci@gmail.com
        </a>
      </div>
    );
  }

  return null;
};

const PreviewStep = ({
  cart,
  visibleSection,
  note,
  cartFilesSize,
  name,
  email,
  phone,
  terms,
  newsletter,
  deliveryMethod,
  registrationName,
  registrationEmail,
  registrationPhone,
  registrationPassword,
  registrationPasswordConfirmation,
  registrationNewsletter,
  registrationTerms,
  hasInvoice,
  invoiceInfo,
  discountCode,
  voucher,
  hasDiscount,
  selectedCourier,
  deliveryPrice,
  totalWithDelivery,
  freeDelivery,
  courierId,
  deliveryAddress,
  deliveryCity,
  csrf,
  submitUrl,
  backUrl,
  customer,
  personalizationNames,
  getVoucherDiscount,
  getCustomerDiscount,
}) => {
  const totalAmount = () => {
    if (shouldHaveVoucherDiscount(voucher, customer)) {
      return totalWithDelivery - getVoucherDiscount(voucher, totalWithDelivery);
    }

    if (shouldHaveCustomerdiscount(customer)) {
      return (
        totalWithDelivery -
        getCustomerDiscount(customer, totalWithDelivery - deliveryPrice)
      );
    }

    return totalWithDelivery;
  };

  return (
    <div className="summary">
      <form method="post" action={submitUrl} onSubmit={handleSubmit}>
        <p>
          <strong>
            Моля, проверете Вашата поръчка и данни внимателно и ако всичко е
            наред, завършете поръчката натискайки бутона отдолу.
          </strong>
        </p>

        <div className="btn-step-toolbar">
          <FilesSizeMessage cartFilesSize={cartFilesSize} />

          <button
            type="submit"
            className="btn-success btn-lg btn-step-navigation"
            disabled={SubmitDisabled(cartFilesSize)}
          >
            ЗА ДА ИЗПРАТИТЕ ПОРЪЧКАТА, МОЛЯ НАТИСНЕТЕ ТУК!
          </button>
        </div>

        {visibleSection === "register" && (
          <div>
            <p>
              След завършване на поръчката ще получите писмо с линк за
              активиране на Вашия профил. Моля последвайте го за да активирате
              своя акаунт.
            </p>
            <p className="mini-spacing">
              Ако до няколко минути не получите имейл от нас, моля проверете си
              СПАМ папката, ако случайно имейлът ни е отишъл в нея. В този
              случай, моля маркирайте имейла и го потвърдете, че не получавате
              СПАМ от нас. По този начин следващите ни имейли ще отиват във
              входящата Ви поща.{" "}
            </p>
          </div>
        )}

        <h3 className="sub-section-title">Продукти</h3>

        <table>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Бройка</th>
              <th>Ед. цена</th>
            </tr>
          </thead>
          <tbody>
            {cart.cart_items.map((item) => (
              <tr key={item.id}>
                <td className="item-info">
                  <Img src={item.image} variant="thumb" />
                  <div>
                    {item.product_name}{" "}
                    {item.express && (
                      <span className="express">(експресна поръчка)</span>
                    )}
                    <ProductPersonalizations
                      personalizationNames={personalizationNames}
                      personalizations={item.personalizations}
                    />
                  </div>
                </td>
                <td className="number">{item.quantity} бр.</td>
                <td className="number">
                  <Currency number={item.total} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {deliveryMethod === "courier" && (
              <tr>
                <th colSpan="2">{selectedCourier && selectedCourier.label}</th>
                <td className="number">
                  {freeDelivery ? (
                    "Безплатна доставка"
                  ) : (
                    <Currency number={deliveryPrice} />
                  )}
                </td>
              </tr>
            )}
            {shouldHaveVoucherDiscount(voucher, customer) && (
              <tr>
                <th colSpan="2">Отстъпка от ваучер</th>
                <td className="number">
                  <Currency
                    number={getVoucherDiscount(voucher, totalWithDelivery)}
                  />
                </td>
              </tr>
            )}
            {shouldHaveCustomerdiscount(customer) && (
              <tr>
                <th colSpan="2" className="total">
                  Отстъпка специален клиент ({customer.discount_percentage}%)
                </th>
                <td className="number">
                  <Currency
                    number={getCustomerDiscount(
                      customer,
                      totalWithDelivery - deliveryPrice,
                    )}
                  />
                </td>
              </tr>
            )}
            <tr>
              <th colSpan="2" className="total">
                Общо
              </th>
              <td className="number">
                <Currency number={totalAmount()} />
              </td>
            </tr>
          </tfoot>
        </table>

        {hasDiscount && (
          <div className="discount-note sub-section-padding-bottom">
            При над 2 броя продукт с еднакъв дизайн, предлагаме отстъпки. Наш
            сътрудник ще се свърже с Вас за уточнение, след преглед на
            материалите.
          </div>
        )}

        <h3 className="sub-section-title">Информация за клиента</h3>

        {(customer || name) && (
          <table>
            <tbody>
              <tr>
                <th>Име</th>
                <td>{customer ? customer.name : name}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{customer ? customer.email : email}</td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>{customer ? customer.phone : phone}</td>
              </tr>
            </tbody>
          </table>
        )}

        {visibleSection === "register" && (
          <table>
            <tbody>
              <tr>
                <th>Име</th>
                <td>{registrationName}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{registrationEmail}</td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>{registrationPhone}</td>
              </tr>
            </tbody>
          </table>
        )}

        <h3 className="sub-section-title">Информация за доставката</h3>

        <table>
          <tbody>
            <tr>
              <th>Начин на доставка</th>
              <td>
                {deliveryMethod === "pickup"
                  ? "Ще взема поръчката си от магазин Уникални подаръци, ул. Цар Асен 11"
                  : "Куриер"}
              </td>
            </tr>
            {deliveryMethod === "courier" && (
              <tr>
                <th>{selectedCourier && selectedCourier.label}</th>
                <td>{`${deliveryAddress}, ${deliveryCity}`}</td>
              </tr>
            )}
            {hasInvoice && (
              <tr>
                <th>Данни за фактура</th>
                <td>{invoiceInfo}</td>
              </tr>
            )}
          </tbody>
        </table>

        {customer && (
          <input type="hidden" name="order[customer_id]" value={customer.id} />
        )}
        <input
          type="hidden"
          name="order[customer][name]"
          value={customer ? customer.name : name}
        />
        <input
          type="hidden"
          name="order[customer][email]"
          value={customer ? customer.email : email}
        />
        <input
          type="hidden"
          name="order[customer][phone]"
          value={customer ? customer.phone : phone}
        />
        <input
          type="hidden"
          name="order[customer][terms_of_service]"
          value={terms}
        />
        <input
          type="hidden"
          name="order[customer][newsletter]"
          value={customer ? customer.newsletter : newsletter}
        />

        <input
          type="hidden"
          name="order[delivery_method]"
          value={deliveryMethod}
        />
        <input type="hidden" name="order[note]" value={note} />
        <input type="hidden" name="order[discount_code]" value={discountCode} />

        {deliveryMethod === "courier" && (
          <div>
            <input type="hidden" name="order[courier_id]" value={courierId} />
            <input
              type="hidden"
              name="order[address]"
              value={deliveryAddress}
            />
            <input type="hidden" name="order[city]" value={deliveryCity} />
          </div>
        )}

        {hasInvoice && (
          <div>
            <input type="hidden" name="order[has_invoice]" value="1" />
            <input
              type="hidden"
              name="order[invoice_info]"
              value={invoiceInfo}
            />
          </div>
        )}

        {visibleSection === "register" && (
          <div>
            <input
              type="hidden"
              name="order[registration][email]"
              value={registrationEmail}
            />
            <input
              type="hidden"
              name="order[registration][name]"
              value={registrationName}
            />
            <input
              type="hidden"
              name="order[registration][phone]"
              value={registrationPhone}
            />
            <input
              type="hidden"
              name="order[registration][password]"
              value={registrationPassword}
            />
            <input
              type="hidden"
              name="order[registration][password_confirmation]"
              value={registrationPasswordConfirmation}
            />
            <input
              type="hidden"
              name="order[registration][terms_of_service]"
              value={registrationTerms === true ? "1" : "0"}
            />
            <input
              type="hidden"
              name="order[registration][newsletter]"
              value={registrationNewsletter}
            />
            {registrationNewsletter && (
              <input
                type="hidden"
                name="order[registration][newsletter]"
                value="1"
              />
            )}
          </div>
        )}

        <input type="hidden" name="authenticity_token" value={csrf} />

        <div className="btn-step-toolbar">
          <FilesSizeMessage cartFilesSize={cartFilesSize} />

          <button
            type="submit"
            className="btn-success btn-lg btn-step-navigation"
            disabled={SubmitDisabled(cartFilesSize)}
          >
            ЗА ДА ИЗПРАТИТЕ ПОРЪЧКАТА, МОЛЯ НАТИСНЕТЕ ТУК!
          </button>
        </div>

        <div className="btn-step-toolbar">
          <a className="btn-primary btn-step-navigation" href={backUrl}>
            назад
          </a>
        </div>
      </form>
    </div>
  );
};

export default PreviewStep;
