import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";

const backgroundType = (background) => {
  if (typeof background === "string") {
    return background;
  }

  return background.urls.original;
};

const PageTitleWidget = ({ pageTitle, background, containerSettings }) => (
  <Container className="page-title" settings={containerSettings}>
    <div
      className="page-title-background"
      style={{ backgroundImage: "url(" + backgroundType(background) + ")" }}
    >
      <div className="title-wrapper">
        <h1>{pageTitle}</h1>
      </div>
    </div>
  </Container>
);

PageTitleWidget.title = "Page Title";
PageTitleWidget.category = "General";

PageTitleWidget.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  background: PropTypes.any.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

PageTitleWidget.defaults = {
  background: imagePlaceholder("1120x720"),
  pageTitle: "Page Title",
  containerSettings: {},
};

PageTitleWidget.adminFields = {
  background: { type: "custom", component: ImagePicker },
  pageTitle: { type: "text" },
};

export default PageTitleWidget;
