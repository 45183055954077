import React from "react";
import PropTypes from "prop-types";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import ConditionalElement from "../ConditionalElement";

const ImageWidget = ({
  image,
  layout,
  description,
  link,
  containerSettings,
}) => (
  <Container className="container" settings={containerSettings}>
    <div className="row">
      <ConditionalElement
        tagName="div"
        condition={layout === "shifted"}
        attributes={{ className: "shift-2" }}
        childrenElements={
          <div className="image-widget col-12">
            <figure>
              {link && (
                <a href={link}>
                  <Img
                    src={image}
                    variant={layout === "long" ? "span_three" : "image"}
                  />
                </a>
              )}
              {!link && (
                <Img
                  src={image}
                  variant={layout === "long" ? "span_three" : "image"}
                />
              )}

              {description === "" ? "" : <figcaption>{description}</figcaption>}
            </figure>
          </div>
        }
      />
    </div>
  </Container>
);

ImageWidget.title = "Image";
ImageWidget.category = "Media";

ImageWidget.propTypes = {
  image: PropTypes.any.isRequired,
  layout: PropTypes.string.isRequired,
  link: PropTypes.string,
  description: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ImageWidget.defaultProps = {
  link: "",
};

ImageWidget.defaults = {
  image: imagePlaceholder("1000x720"),
  description: "Image description",
  layout: "full-width",
  link: "",
  containerSettings: {},
};

ImageWidget.adminFields = {
  layout: {
    type: "select",
    options: [
      ["full-width", "Full Width"],
      ["shifted", "Shifted"],
      ["long", "Long"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  description: { type: "text" },
  link: { type: "text", label: "Link (optional)" },
};

export default ImageWidget;
