import React from "react";
import PropTypes from "prop-types";
import { Container, List, SelectMenu } from "@raketa-cms/raketa-cms";
import { imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import ProductPicker from "../../ProductPicker";
import ProductFeedSixts from "./ProductFeedSixts";
import ProductFeedFifts from "./ProductFeedFifts";
import ProductFeedQuarters from "./ProductFeedQuarters";
import ProductFeedSlim from "./ProductFeedSlim";

const ProductFeedWidget = ({ list, variant, containerSettings }) => {
  let Component;

  if (variant === "6_columns") Component = ProductFeedSixts;
  if (variant === "5_columns") Component = ProductFeedFifts;
  if (variant === "4_columns") Component = ProductFeedQuarters;
  if (variant === "slim") Component = ProductFeedSlim;

  return (
    <Container className="product-feed" settings={containerSettings}>
      <Component list={list} />
    </Container>
  );
};

ProductFeedWidget.title = "Product Feed";
ProductFeedWidget.category = "General";
ProductFeedWidget.dialogSize = "large";

ProductFeedWidget.defaultProps = {
  list: [],
};

ProductFeedWidget.propTypes = {
  list: PropTypes.array,
  variant: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ProductFeedWidget.defaults = {
  list: [
    {
      id: 1,
      product: {
        id: 1,
        name: "Test product",
        base_price: "13",
        image: imagePlaceholder("500x500"),
      },
      oldPrice: "",
      productStatus: "old",
    },
  ],
  variant: "6_columns",
  containerSettings: {},
};

const ProductFields = ({ settings, onChangeItem }) => (
  <div>
    <ProductPicker
      onChange={(value) => onChangeItem("product", value)}
      value={settings.product}
    />
  </div>
);

ProductFields.defaultProps = {
  settings: {},
};

ProductFields.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

ProductFeedWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["6_columns", "6 Columns"],
        ["5_columns", "5 Columns"],
        ["4_columns", "4 Columns"],
        ["slim", "Slim"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      factory={ProductFeedWidget.defaults.list[0]}
      listItem={(settings, onChangeItem) => (
        <ProductFields settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      template={[
        {
          product: {
            id: 1,
            name: "Test product",
            base_price: "13",
            image: imagePlaceholder("500x500"),
          },
          oldPrice: "",
          productStatus: "old",
        },
      ]}
      primaryField="name"
    />
  </div>
);

export default ProductFeedWidget;
